import { Button, DatePicker, Input, Select, message } from 'antd';
import React, { useState } from 'react';
import { usePortfolio } from '../../Layout/baseContext/portfolioContext/PortfolioContext';
import moment from 'moment';
import dayjs from 'dayjs';
import { useBase } from '../../Layout/baseContext/BaseContext';
import api from '../../../api';
import { useDashboard } from '../../Layout/baseContext/dashboardContext/DashboardContext';

const TransitionForm = ({ locationOptions, customerOptions, productOptions }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { selectedChip, setOldTransitionForm, selectedListItem } = usePortfolio();
    const { fetchActivities } = useDashboard();
    const { uiMappingData } = useBase();

    const [formData, setFormData] = useState({
        item: [], // Handle as an array for multi-select
        location: [], // Handle as an array for multi-select
        customer: [], // Handle as an array for multi-select
        startDate: null,
        phaseoutBuckets: Array(6).fill(''), // Initialize an array with 6 empty strings for phaseout
        phaseinBuckets: Array(6).fill(''),  // Initialize an array with 6 empty strings for phasein
    });

    const handleDateChange = (date, dateString, field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: dateString,
        }));
    };

    const handleSelectChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value, // Directly set the array of selected values
        }));
    };

    const handlePhaseoutChange = (index, value) => {
        const updatedBuckets = [...formData.phaseoutBuckets];
        updatedBuckets[index] = value;
        setFormData((prevData) => ({
            ...prevData,
            phaseoutBuckets: updatedBuckets,
        }));
    };

    const handlePhaseinChange = (index, value) => {
        const updatedBuckets = [...formData.phaseinBuckets];
        updatedBuckets[index] = value;
        setFormData((prevData) => ({
            ...prevData,
            phaseinBuckets: updatedBuckets,
        }));
    };

    const userID = localStorage.getItem("userid");

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const generateActivityId = () => {
            return moment().format('YYYYMMDDHHmmssSSS'); // Format: YYYYMMDDHHmmssSSS (milliseconds included)
        };
        const activityId = generateActivityId();

        const generateDateKeys = (startDate, buckets) => {
            return buckets.reduce((acc, value, index) => {
                const dateKey = moment(startDate).add(index, 'months').format('YYYY-MM-DD');
                acc[dateKey] = value;
                return acc;
            }, {});
        };

        // Construct formatted data based on the selectedChip logic
        const formattedData = {
            ...formData,
            startDate: formData.startDate ? moment(formData.startDate).format('YYYY-MM-DD') : null,
            activityId,
            userID,
            oldItem: selectedListItem.ITEM,
            oldLocation: selectedListItem.LOCATION,
            oldCustomer: selectedListItem.CUSTOMER,
            phaseoutBuckets: generateDateKeys(formData.startDate, formData.phaseoutBuckets),
            phaseinBuckets: generateDateKeys(formData.startDate, formData.phaseinBuckets),
        };

        if (formData.item.length === 0 && selectedChip === 'products') {
            messageApi.warning("Please select at least one item");
            return;
        } else if (formData.location.length === 0 && selectedChip === 'locations') {
            messageApi.warning("Please select at least one location");
            return;
        } else if (formData.customer.length === 0 && selectedChip === 'customers') {
            messageApi.warning("Please select at least one customer");
            return;
        }
        if (formData.startDate === null) {
            messageApi.warning("Please select a Start Date");
            return;
        }

        try {
            const response = await api.post("/api/u1_transition_profile/", formattedData);
            if (response.status === 401) {
                messageApi.error("Token Expired, Reload the page");
            } else {
                fetchActivities();
                setFormData({
                    item: [],
                    location: [],
                    customer: [],
                    startDate: null,
                    phaseoutBuckets: Array(6).fill(''),
                    phaseinBuckets: Array(6).fill(''),
                });
                messageApi.success("Transition successfully submitted");
            }
        } catch (error) {
            console.error('Error:', error);
            messageApi.error("Something went wrong");
        }
    };

    return (
        <form className='flex flex-col h-full justify-between overflow-y-scroll' onSubmit={handleFormSubmit}>
            {contextHolder}
            <div className='flex flex-col gap-6 mt-4 h-[calc(100%-5rem)] overflow-y-scroll'>
                <div className='flex flex-col gap-2'>
                    <div>
                        <h3 className='font-semibold text-base'>Basic Details</h3>
                    </div>

                    <div className='grid grid-cols-4 gap-4 items-center'>
                        <div className='flex flex-col gap-1 justify-between'>
                            <label htmlFor="item">{uiMappingData[0]['ITEM']}</label>

                            <Select
                                className='selectTag'
                                showSearch
                                mode="multiple" // Enable multi-select
                                placeholder="Select"
                                style={{
                                    height: "2.5rem",
                                    borderRadius: "2rem",
                                    margin: "0",
                                }}
                                value={formData.item}
                                onChange={(value) => handleSelectChange('item', value)}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={productOptions}
                                maxTagCount= 'responsive'

                            />

                        </div>
                        <div className='flex flex-col gap-1 justify-between'>
                            <label htmlFor="location">{uiMappingData[0]['LOCATION']}</label>

                            <Select
                                className='selectTag'
                                showSearch
                                mode="multiple" // Enable multi-select
                                placeholder="Select"
                                style={{
                                    height: "2.5rem",
                                    borderRadius: "2rem",
                                    margin: "0",
                                }}
                                value={formData.location}
                                onChange={(value) => handleSelectChange('location', value)}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={locationOptions}
                                maxTagCount= 'responsive'

                            />
                        </div>
                        <div className='flex flex-col gap-1 justify-between'>
                            <label htmlFor="customer">{uiMappingData[0]['CUSTOMER']}</label>

                            <Select
                                className='selectTag'
                                showSearch
                                mode="multiple" // Enable multi-select
                                style={{
                                    height: "2.5rem",
                                    borderRadius: "2rem",
                                    margin: "0",
                                }}
                                placeholder="Select"
                                value={formData.customer}
                                onChange={(value) => handleSelectChange('customer', value)}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={customerOptions}
                                maxTagCount= 'responsive'
                            />
                        </div>
                        <div className='flex flex-col gap-1 justify-between'>
                            <label htmlFor="startDate">Start Date</label>
                            <DatePicker
                                className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'
                                value={formData.startDate ? dayjs(formData.startDate) : null}
                                onChange={(date, dateString) => handleDateChange(date, dateString, 'startDate')}
                            />
                        </div>
                    </div>
                </div>

                <hr />
                <div className='flex gap-4 w-full'>
                    <div className='w-full border-r pr-4'>
                        <div>
                            <h3 className='font-semibold text-base'>Phase in Percentage Buckets</h3>
                        </div>

                        <div className='grid grid-cols-2 gap-4'>
                            {Array.from({ length: 6 }, (_, index) => (
                                <div key={index} className='flex flex-col gap-1'>
                                    <label htmlFor={`phaseinBucket${index + 1}`}>B{index + 1}</label>
                                    <Input
                                        name={`phaseinBucket${index + 1}`}
                                        value={formData.phaseinBuckets[index]}
                                        onChange={(e) => handlePhaseinChange(index, e.target.value)}
                                        placeholder={`Enter percentage for bucket ${index + 1}`}
                                        className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'

                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='w-full'>
                        <div>
                            <h3 className='font-semibold text-base'>Phase out Percentage Buckets</h3>
                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                            {Array.from({ length: 6 }, (_, index) => (
                                <div key={index} className='flex flex-col gap-1'>
                                    <label htmlFor={`phaseoutBucket${index + 1}`}>B{index + 1}</label>
                                    <Input
                                        name={`phaseoutBucket${index + 1}`}
                                        value={formData.phaseoutBuckets[index]}
                                        onChange={(e) => handlePhaseoutChange(index, e.target.value)}
                                        placeholder={`Enter percentage for bucket ${index + 1}`}
                                        className='rounded-md m-0 bg-slate-100 py-2 border-blue-100'

                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                </div>            </div>
            <div className='flex justify-between items-center w-full'>
                <div>
                    <Button type='link' onClick={() => setFormData({
                        item: [],
                        location: [],
                        customer: [],
                        startDate: null,
                        phaseoutBuckets: Array(6).fill(''),
                        phaseinBuckets: Array(6).fill(''),
                    })}>Reset</Button>
                </div>
                <div>
                    <Button className='rounded-md' type="primary" htmlType="submit">Submit</Button>
                </div>
            </div>
        </form>
    );
};

export default TransitionForm;
