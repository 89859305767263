import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../../../../api';
import { message } from 'antd';

const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
    const categories = {
        'Master Data': ['Item', 'Location', 'Customer', 'Calendars (Holiday, Shipment)'],
        'Transactions': ['Sales', 'Orders', 'Shipments', 'Confirmed Orders', 'Open Orders', 'Past Due Orders', 'Point Of Sale', 'Sell Out', 'Inventory'],
        'Initiatives': ['Price', 'Promotions', 'Events', 'CRM', 'Quotes', 'Proposals'],
        'External': ['Weather', 'Indexes']
    };

    const [demandTabs, setDemandTabs] = useState(['Summary', 'Item Segment', 'Location Segment', 'Customer Segment'])
    const [activeDemandTab, setActiveDemandTab] = useState('Summary')
    const [demandLoading, setdemandLoading] = useState(false)
    const [itemSegmentData, setItemSegmentData] = useState([])
    const [locationSegmentData, setLocationSegmentData] = useState([])
    const [customerSegmentData, setCustomerSegmentData] = useState([])
    const [engineOrdersData, setEngineOrdersData] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableHeaders, setTableHeaders] = useState([])
    const [tableHeadValue, setTableHeadValue] = useState([])

    const [selectedCategory, setselectedCategory] = useState(null);
    const [configBox, setConfigBox] = useState(null)
    const [selectedArea, setSelectedArea] = useState("uiMapping");
    const [tabList, setTabList] = useState(["upload", "params", "uiMapping"])
    const [runDemandScanLoading, setRunDemandScanLoading] = useState(false)
    const [demandGraphData, setDemandGraphData] = useState(null)
    const [runDemandSuccess, setRunDemandSuccess] = useState(null)
    const [preProcessLoading, setPreProcessLoading] = useState(false)
    const [preProcessSuccess, setPreProcessSuccess] = useState(null)

    const [goToUploadData, setGoToUploadData] = useState('')

    const processData = (data, countData) => {
        const segments = ["ISEGMENT1", "ISEGMENT2", "ISEGMENT3", "ISEGMENT4", "ISEGMENT5", "ISEGMENT6", "LSEGMENT1", "LSEGMENT2", "LSEGMENT3", "LSEGMENT4", "LSEGMENT5", "LSEGMENT6", "CSEGMENT1", "CSEGMENT2", "CSEGMENT3", "CSEGMENT4", "CSEGMENT5", "CSEGMENT6"];
        const result = {};

        // Determine which key to use for SQTY calculation
        const keyType = data[0].ITEM ? "ITEM" : data[0].LOCATION ? "LOCATION" : "CUSTOMER";
        // Helper function to calculate sum of SQTY for specific items, locations, or customers
        const calculateSQTYSum = (keys) => {
            return countData
                .filter((entry) => keys.includes(entry[keyType]))
                .reduce((acc, entry) => acc + (entry.SQTY || 0), 0);
        };

        segments.forEach((segment) => {
            // Get unique values in the segment for x-axis
            const uniqueValues = [...new Set(data.map((item) => item[segment]))].filter((val) => val !== "NaN");

            // Count occurrences for each unique value and calculate count based on SQTY
            const volumeCount = {};
            const sqtyCount = {};

            uniqueValues.forEach((value) => {
                // Filter items with the current segment value
                const itemsWithSegmentValue = data.filter((item) => item[segment] === value);
                const segmentKeys = itemsWithSegmentValue.map((item) => item[keyType]);

                // Volume count
                volumeCount[value] = itemsWithSegmentValue.length;

                // SQTY count (sum of SQTY for matching ITEMs, LOCATIONs, or CUSTOMERs in countData)
                sqtyCount[value] = calculateSQTYSum(segmentKeys);
            });

            // Set up the structure for each segment
            result[segment] = {
                "x-axis": uniqueValues,
                "y-axis": {
                    count: volumeCount,
                    volume: sqtyCount,
                },
            };
        });

        return result;
    };

    const handleRunDemandScan = async () => {
        setRunDemandScanLoading(true)
        setRunDemandSuccess(null)
        setPreProcessSuccess(null)
        try {
            const response = await api.post("/api/rundemandscan/");
            if (response.status === 200) {
                message.success('Demand Scan successfully run!');
            } else {
                message.error('Failed to run Demand Scan.');
            }
            setRunDemandSuccess(true)
        } catch (error) {
            message.error('Error running Demand Scan.');
            console.error(error);
            setRunDemandSuccess(false)
        }
        setRunDemandScanLoading(false)
        getTableData()
    };

    const handlePreProcess = async () => {
        setPreProcessLoading(true)
        setRunDemandSuccess(null)
        setPreProcessSuccess(null)
        try {
            const response = await api.post("/api/preprocess/");
            if (response.status === 200) {
                message.success('Pre process successfully run!');
            } else {
                message.error('Failed to run Pre process');
            }
            setPreProcessSuccess(true)
        } catch (error) {
            message.error('Error running preprocess');
            console.error(error);
            setPreProcessSuccess(false)
        }
        setPreProcessLoading(false)
    };


    const getTableData = async () => {
        setdemandLoading(true)
        try {
            const [
                itemSegments,
                locationSegments,
                customerSegments,
                engineOrders
            ] = await Promise.all([
                api.get('/api/products/?fields=ITEM,ISEGMENT1,ISEGMENT2,ISEGMENT3,ISEGMENT4,ISEGMENT5,ISEGMENT6'),
                api.get('/api/locations/?fields=LOCATION,LSEGMENT1,LSEGMENT2,LSEGMENT3,LSEGMENT4,LSEGMENT5,LSEGMENT6'),
                api.get('/api/customers/?fields=CUSTOMER,CSEGMENT1,CSEGMENT2,CSEGMENT3,CSEGMENT4,CSEGMENT5,CSEGMENT6'),
                api.get('/api/sales/?fields=ITEM,LOCATION,CUSTOMER')
            ]);
            setItemSegmentData(itemSegments.data)
            setLocationSegmentData(locationSegments.data)
            setCustomerSegmentData(customerSegments.data)
            setEngineOrdersData(engineOrders.data.results)




        }
        catch (e) {
            console.log(e);
        }
        setdemandLoading(false)
    }


    // Define a function to update table headers based on demandTab
    const updateTableHeaders = () => {
        const paramsData = JSON.parse(localStorage.getItem('params'));

        // Determine the prefix based on the demandTab
        const segmentPrefix = activeDemandTab === 'Item Segment' ? 'ISEGMENT' :
            activeDemandTab === 'Location Segment' ? 'LSEGMENT' :
                activeDemandTab === 'Customer Segment' ? 'CSEGMENT' : null;

        if (segmentPrefix) {
            // Filter paramsData to get only relevant segments
            const filteredHeaders = paramsData
                .filter(param => param.PARAM_NAME.startsWith(segmentPrefix))
                .map(param => param.PARAM_NAME);

            setTableHeaders(filteredHeaders);


            const filteredHeadersValue = paramsData
                .filter(param => param.PARAM_NAME.startsWith(segmentPrefix))
                .map(param => param.DEFAULT_VALUE);
            setTableHeadValue(filteredHeadersValue)
        }
    };
    useEffect(() => {

        if (activeDemandTab === 'Item Segment' && itemSegmentData.length !== 0) {
            setTableData(itemSegmentData)
            const graphData = processData(itemSegmentData, engineOrdersData)
            const filteredData = Object.keys(graphData)
                .filter(key => key.startsWith("ISEG"))
                .reduce((result, key) => {
                    result[key] = graphData[key];
                    return result;
                }, {});
            setDemandGraphData(filteredData)
        }
        if (activeDemandTab === 'Location Segment' && locationSegmentData.length !== 0) {
            setTableData(locationSegmentData)
            const graphData = processData(locationSegmentData, engineOrdersData)
            const filteredData = Object.keys(graphData)
                .filter(key => key.startsWith("LSEG"))
                .reduce((result, key) => {
                    result[key] = graphData[key];
                    return result;
                }, {});

            setDemandGraphData(filteredData)

        }
        if (activeDemandTab === 'Customer Segment' && customerSegmentData.length !== 0) {
            setTableData(customerSegmentData)
            const graphData = processData(customerSegmentData, engineOrdersData)
            const filteredData = Object.keys(graphData)
                .filter(key => key.startsWith("CSEG"))
                .reduce((result, key) => {
                    result[key] = graphData[key];
                    return result;
                }, {});

            setDemandGraphData(filteredData)

        }
        updateTableHeaders()
    }, [activeDemandTab])

    useEffect(() => {
        getTableData();
    }, [])

    return (
        <UploadContext.Provider
            value={{
                categories,
                selectedCategory, setselectedCategory,
                configBox, setConfigBox,
                selectedArea, setSelectedArea,
                tabList, setTabList,
                handleRunDemandScan,
                demandTabs, setDemandTabs,
                activeDemandTab, setActiveDemandTab,
                tableData,
                tableHeaders, tableHeadValue,
                runDemandScanLoading,
                demandLoading,
                demandGraphData, setDemandGraphData,
                runDemandSuccess,
                goToUploadData, setGoToUploadData,
                handlePreProcess,
                preProcessLoading,
                preProcessSuccess
            }}>
            {children}
        </UploadContext.Provider>
    );
};

export const useUpload = () => useContext(UploadContext);