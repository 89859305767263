import { React, useRef, useState } from "react";

import {
  Button,
  Input,
  Menu, Space, Tooltip,
} from "antd";
import {
  AlignCenterOutlined,
  MenuOutlined,
  RiseOutlined,
  CalendarOutlined,
  BuildOutlined,
  NotificationFilled,
  FilterOutlined,
} from "@ant-design/icons/lib/icons";
import { LuFilterX } from "react-icons/lu";
import ai from "../../assets/img/ai.jpg";
import { useEffect } from "react";
import "../../../css/header.css";
import FilterButton from "../../filterButton";
import { useBase } from "../baseContext/BaseContext";
import ProfilePopUp from "./ProfilePopUp";
import { IoBookmarkOutline, IoBookmark } from "react-icons/io5";
import { LuRefreshCw } from "react-icons/lu";


const HeaderComponent = ({selectedArea, navClose, setSelectedArea, locationFilters, productFilters, customerFilters, uiMappingData, filterString, setFilterString }) => {

  // setColSpanBol(navClose.navClose)
  const [colSpan, setColSpan] = useState(3);
  const {selectedMenu, saveFavourite, openSaveFav, setOpenSaveFav, handleRefresh } = useBase()
  const [openProPic, setOpenProPic] = useState(false)
  const [bookHovered, setbookHovered] = useState(false);
  const profilePopUpRef = useRef(null);
  const saveFavouriteRef = useRef(null)
  const [favouriteName, setFavouriteName] = useState('');

  // const [current, setCurrent] = useState("planning");
  const onClick = (e) => {
    if (selectedMenu !== 'supplyViewBase') {
      setSelectedArea(e.key);
    }
  };
  useEffect(() => {
    if (navClose) {

      setColSpan(2);
    } else {

      setColSpan(3);
    }

  }, [navClose]);

  var items = [];
  if (selectedMenu === 'supplyViewBase') {
    items = [
      {
        label: "Supply Review",
        key: "supply",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon"
      },
      {
        label: "Constraints",
        key: "Constraints",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon"
      },
      {
        label: "Inventory",
        key: "Inventory",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon"
      },
      {
        label: "Costs",
        key: "Costs",

        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon"
      },
      {
        label: "Supplier",
        key: "Supplier",

        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon"
      },
      {
        label: "Parameter Setup",
        key: "Parameter",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon"
      },
      {
        label: "Data",
        key: "Data",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon"
      },
    ];
  }
  else if (selectedMenu === 'inventoryReviewBase') {
    items = [
      {
        label: "Inventory Review",
        key: "inventory",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon",
      }
    ]
  }
  else if (selectedMenu === 'dataSection') {
    items = [
      {
        label: "Upload Data",
        key: "uploadTab",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon",
      },
      {
        label: "Parameter",
        key: "parameter",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon",
      },
      {
        label: "UI Mapping",
        key: "uiMapping",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon",
      },
      {
        label: "Demand Scan",
        key: "demandScan",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon",
      }
    ]
  }
  else {
    items = [
      {
        label: "Planning Overview",
        key: "planning",
        icon: <MenuOutlined style={{ padding: 0 }} />,
        className: "menuitemicon",


      },
      {
        label: "Portfolios",
        key: "portfolio",
        className: "menuitemicon ",
        icon: (
          <div>
            <i class="fa-solid fa-suitcase"></i>
          </div>
        ),
      },
      {
        label: "Performance",
        key: "performance",
        icon: <RiseOutlined />,
        className: "menuitemicon",
      },
      {
        label: "Posts",
        key: "post",
        icon: <BuildOutlined />,
        className: "menuitemicon",
      },
      {
        label: "Alerts",
        key: "alert",
        icon: <NotificationFilled />,
        className: "menuitemicon"
      },
      {
        label: " Methods",
        key: "method",
        icon: <CalendarOutlined />,
        className: "menuitemicon",
      },
      {
        label: "Segments",
        key: "segment",
        icon: <AlignCenterOutlined />,
        className: "menuitemicon",
      },
    ];
  }
  const { setFields } = useBase()

  const clearHandler = () => {
    const storedFields = JSON.parse(localStorage.getItem('fields'));

    if (storedFields) {
      setFields(prevFields => ({
        ...prevFields,
        item: storedFields.item,
        location: storedFields.location,
        category: storedFields.category
      }));
    }

    setFilterString("")
  }

  // Handle clicks outside ProfilePopUp
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the popup and not on the image
      if (profilePopUpRef.current && !profilePopUpRef.current.contains(event.target)) {
        setOpenProPic(false);
      }
    };

    // Add event listener to detect clicks on the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [profilePopUpRef]);
  // Handle clicks outside ProfilePopUp
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the popup and not on the image
      if (saveFavouriteRef.current && !saveFavouriteRef.current.contains(event.target)) {
        setOpenSaveFav(false);
      }
    };

    // Add event listener to detect clicks on the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [saveFavouriteRef]);
  return (
    <div className="mainHeaderDiv border-b w-full h-full  border-b-gray-300 flex items-center pr-4 ">
      <div className=" w-full h-full flex items-center " >
        <div className="h-full  w-full flex flex-row justify-between items-center ">
          <Menu
            mode="horizontal"
            onClick={onClick}
            selectedKeys={selectedArea}
            items={items}
            className="HeadermenuItem h-full pt-0 pb-0 lg:pt-2"
          />
          <div className="flex items-center gap-x-5 ">
            <div>
              <div onClick={handleRefresh} className="flex flex-row  items-center text-lg hover:text-blue-500 hover:scale-110 transition-all duration-200 ease-in-out rounded-full cursor-pointer text-slate-400 font-bold">
                <LuRefreshCw />
              </div>
            </div>
            {
              
              <div className="flex items-center gap-x-2">

                {filterString !== "" ? <div className='bg-slate-200 flex flex-row px-3 py-1 rounded-full text-red-500 cursor-pointer items-center hover:bg-slate-300' onClick={() => clearHandler()} ><span className="flex items-center gap-x-1"><span className="text-lg"><LuFilterX /></span>Clear Filters</span> </div> : null}

                <FilterButton filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilters} productFilters={productFilters} customerFilters={customerFilters} uiMappingData={uiMappingData !== null ? uiMappingData : null}>{<div><FilterOutlined /> Filter</div>}</FilterButton>
              </div>
            }

            <div className="flex items-center text-base px-1 pl-2 text-slate-400 gap-2 py-1 box-shadow rounded-full">

              <div
                onClick={() => setOpenSaveFav(true)}
                className={`${bookHovered ? "text-yellow-500" : ""} cursor-pointer relative`}
                onMouseEnter={() => setbookHovered(true)}
                onMouseLeave={() => setbookHovered(false)}
              >
                <div>

                  {
                    bookHovered ?
                      <IoBookmark />
                      :
                      <IoBookmarkOutline />
                  }
                </div>
                {
                  openSaveFav &&
                  <div ref={saveFavouriteRef} className="absolute flex flex-col gap-3 top-10 -right-14 w-56 bg-white box-shadow rounded-lg p-3 text-bodyTextBlue">
                    <p>Save favourite as:</p>
                    <Input
                      placeholder="Favourite name..."
                      value={favouriteName} // Controlled input
                      onChange={(e) => setFavouriteName(e.target.value)} // Update state on change
                    />
                    <Button className="w-fit" type="primary" onClick={() => { saveFavourite(favouriteName); setOpenSaveFav(false) }}>Save</Button>
                  </div>
                }
              </div>
              <i class="fa-regular fa-bell"></i>


              <div className="img_sec relative">
                <img onClick={(() => setOpenProPic(!openProPic))} src={ai} width={30} className="cursor-pointer rounded-full aspect-square hover:scale-110 transition-all duration-200 ease-in-out" alt="" />
                {
                  openProPic &&
                  <div ref={profilePopUpRef}> {/* Reference to the ProfilePopUp container */}
                    <ProfilePopUp />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
};

export default HeaderComponent;
