import React, { useEffect, useRef, useState } from 'react'; // Import useState
import { Button, message, Tour, Upload } from 'antd';
import { Tooltip } from 'react-tooltip';
import { useUpload } from '../../../Layout/baseContext/uploadContext/UploadContext';
import { GrConfigure } from "react-icons/gr";
import { IoMdArrowRoundBack } from 'react-icons/io';
import * as XLSX from 'xlsx';
import { useBase } from '../../../Layout/baseContext/BaseContext';
import api from '../../../../api';
const UploadDoc = ({ setFirstPage, setConfigurePage }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { uiMappingData, orgType } = useBase();

    const { categories, selectedCategory, setselectedCategory, setConfigBox, handleRunDemandScan, runDemandScanLoading, runDemandSuccess, preProcessLoading,  preProcessSuccess,handlePreProcess } = useUpload();
    const [uploadStatus, setUploadStatus] = useState({}); // State to track upload status
    const [uploadLoading, setUploadLoading] = useState({}); // State to track upload loading status for each category
    const [isTourOpen, setIsTourOpen] = useState(false);
    const itemRef = useRef(null);
    const locationRef = useRef(null);
    const customerRef = useRef(null);
    const salesRef = useRef(null);
    const transRef = useRef(null);
    const demandRef = useRef(null);

    const props = (cat) => ({
        name: 'file',
        multiple: false,
        beforeUpload: (file) => {
            const isSpreadsheet = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                || file.type === 'application/vnd.ms-excel'
                || file.type === 'text/csv';

            const isLt5MB = file.size / 1024 / 1024 < 5; // Change limit to 5 MB

            if (!isSpreadsheet) {
                messageApi.error('You can only upload Excel or CSV files!');
            }
            if (!isLt5MB) {
                messageApi.error('File must be smaller than 5MB!');
            }
            return isSpreadsheet && isLt5MB;
        },


        customRequest({ file, onSuccess, onError }) {
            setUploadLoading((prev) => ({ ...prev, [cat]: true })); // Set loading for the specific category
            const formData = new FormData();
            formData.append('file', file);
            formData.append('category', cat); // Append the category to FormData

            api.post('/api/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                    if (response.data.errors) {
                        messageApi.error('File upload failed due to validation errors.');
                        console.log('Validation errors:', response.data.errors);
                        onError(response.data.errors);
                        setUploadStatus((prev) => ({ ...prev, [cat]: false })); // Reset status on error
                    } else {
                        messageApi.success('File uploaded and processed successfully.');
                        onSuccess('ok');
                        setUploadStatus((prev) => ({ ...prev, [cat]: true })); // Set status to true
                    }
                    setUploadLoading((prev) => ({ ...prev, [cat]: false })); // Reset loading for the specific category
                })
                .catch((error) => {
                    console.error('Upload error:', error);
                    messageApi.error('File upload failed.');
                    onError(error);
                    setUploadStatus((prev) => ({ ...prev, [cat]: false })); // Reset status on error
                    setUploadLoading((prev) => ({ ...prev, [cat]: false })); // Reset loading for the specific category
                });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                messageApi.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                messageApi.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        }
    });

    const handleHome = () => {
        setselectedCategory(null);
        setFirstPage(true);
    };

    const handleConfig = (cat) => {
        setConfigurePage(true);
        setConfigBox(cat);
    };


    // Initialize the output object
    const formattedData = {
        "Item": [],
        "Location": [],
        "Customer": [],
        "Sales": [uiMappingData[0]["ITEM"], uiMappingData[0]["LOCATION"], uiMappingData[0]["CUSTOMER"], "SQTY", "SDATE"]
    };

    // Iterate over the data object
    for (const key in uiMappingData[0]) {
        if (key.startsWith('I')) {
            formattedData["Item"].push(uiMappingData[0][key]);
        } else if (key.startsWith('L')) {
            formattedData["Location"].push(uiMappingData[0][key]);
        } else if (key.startsWith('C')) {
            formattedData["Customer"].push(uiMappingData[0][key]);
        }
    }

    // Function to download an Excel file
    const onDownload = (masterType) => {
     
        const dataToExport = formattedData[masterType];

        // Prepare the data (just one row as requested)
        const worksheetData = [dataToExport];

        // Create a new worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, masterType);

        // Export the Excel file
        XLSX.writeFile(workbook, `${masterType}.xlsx`);
    };
    useEffect(() => {
        const showGuide = localStorage.getItem("showGuide");
        if (showGuide === 'true') {
            setIsTourOpen(true);
        }
    }, []);

    const tourSteps = [
        {
            title: "Upload Item Data",
            description: "Download Template to add data in it and then start by uploading your Item data.",
            target: () => itemRef.current,
        },
        {
            title: "Upload Location Data",
            description: "Download Template to add data in it and then upload your Location data.",
            target: () => locationRef.current,
        },
        {
            title: "Upload Customer Data",
            description: "Download Template to add data in it and then upload your Customer data.",
            target: () => customerRef.current,
        },
        {
            title: "Select Transactions",
            description: "Go to transations to upload sales data",
            target: () => transRef.current,
        },
        {
            title: "Upload Sales Data",
            description: "Download Template to add data in it and then upload your Sales data.",
            target: () => salesRef.current,
        },
        {
            title: "Run Demand Scan",
            description: "Finally, Click on Run demand scan to get insights of your data",
            target: () => demandRef.current,
        },
    ];

    return (
        <div className='flex flex-col  w-full  items-center min-h-screen text-xs text-bodyTextBlue '>
            {contextHolder}
            <Tour
                open={isTourOpen}
                onClose={() => setIsTourOpen(false)}
                steps={tourSteps}
                locale={{ close: "Close" }}
            />
            <div className='flex justify-between  w-full items-center'>
                <div className='flex gap-6 items-center'>
                    <Button onClick={() => handleHome()} className='p-0 text-base text-slate-400' type='link'><IoMdArrowRoundBack />Go back</Button>
                    {
                        Object.entries(categories).map((cat) => (
                            <div ref={cat[0] === 'Transactions' ? transRef : null} onClick={() => setselectedCategory(cat)} className={` py-1 ${selectedCategory[0] === cat[0] ? "text-blue-500 border-b-2 border-blue-500" : ""} cursor-pointer text-lg`}>
                                {cat[0]}
                            </div>
                        ))
                    }
                </div>
                <div className='text-base font-bold flex gap-2 items-center'>
                    {runDemandSuccess &&
                        <p className='text-green-500 font-normal'>Demand Scan successfully done</p>
                    }
                    {runDemandSuccess === false &&
                        <p className='text-red-500 font-normal'>Demand Scan failed</p>
                    }
                    {preProcessSuccess &&
                        <p className='text-green-500 font-normal'>Pre Processing successfully done</p>
                    }
                    {preProcessSuccess === false &&
                        <p className='text-red-500 font-normal'>Pre Processing failed</p>
                    }
                    <Button loading={preProcessLoading} className='min-w-32' onClick={() => handlePreProcess()} type='primary'>{preProcessLoading ? "" : "Pre-Process"}</Button>
                    <Button ref={demandRef} className='min-w-40' loading={runDemandScanLoading} onClick={() => handleRunDemandScan()} type='primary'>{runDemandScanLoading ? "" : "Run Demand Scan"}</Button>
                    <Button disabled={orgType === 'trial'} type='primary'>Download All Templates</Button>
                </div>
            </div>
            <div className='flex flex-wrap w-full gap-4 mt-8'>
                {
                    selectedCategory[1].map((cat, index) => {
                        return (
                            <div key={index} 

                            ref={
                                orgType === 'trial' 
                                  ? (cat === 'Item' ? itemRef 
                                    : cat === 'Location' ? locationRef 
                                    : cat === 'Customer' ? customerRef 
                                    : cat === 'Sales' ? salesRef 
                                    : null)
                                  : null
                              }
                              
                            className={`p-4 bg-white shadow-md flex flex-col gap-4 rounded-lg`}
                            >
                                <div className='flex text-lg items-center justify-between'>
                                    <p className='text-base font-semibold'>{cat}</p>
                                    {
                                        (orgType === 'trial' && (cat !== 'Item' && cat !== 'Location' && cat !== 'Customer' && cat !== 'Sales')) === false &&
                                        <p onClick={() => handleConfig(cat)} className='cursor-pointer hover:text-blue-500' data-tooltip-id="my-tooltip" data-tooltip-content="Configure"><GrConfigure /></p>
                                    }
                                </div>
                                <div className='flex justify-between gap-4'>

                                    <Button disabled={orgType === 'trial' && (cat !== 'Item' && cat !== 'Location' && cat !== 'Customer' && cat !== 'Sales')} onClick={() => onDownload(cat)} type='link' className='p-0 m-0'>Download Template</Button>
                                    <Upload {...props(cat)} >
                                        <Button disabled={orgType === 'trial' && (cat !== 'Item' && cat !== 'Location' && cat !== 'Customer' && cat !== 'Sales')} loading={uploadLoading[cat]} className='border-blue-500 min-w-32' type='link'>{!uploadLoading[cat] && "Upload Data"}</Button>
                                    </Upload>
                                </div>
                                {/* Display upload status */}
                                {uploadStatus[cat] !== undefined && (
                                    <p className={`text-sm ${uploadStatus[cat] ? 'text-green-500' : 'text-red-500'}`}>
                                        {uploadStatus[cat] ? 'File uploaded successfully.' : 'File upload failed.'}
                                    </p>
                                )}
                            </div>
                        )
                    })
                }
            </div>
            <Tooltip id="my-tooltip" />
        </div>
    );
};

export default UploadDoc;
