import React, { useState } from 'react'
import { useAlert } from '../../Layout/baseContext/alertsContext/AlertsContext';
import { FaArrowDownLong } from "react-icons/fa6";
import { Button } from 'antd';
import DFUGraph from './DFUGraph';
import DFUForm from './DFUForm';
import api from '../../../api';
import { message } from "antd";

const DFUAlertsTable = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const {getDFUCorrectedHistory,tableHeaders, DFUTableData, setsortDFUParam,sortDFUParam,showDFUGraph,setShowDFUGraph,handleDFUResolveButton,DFUGraphIndex} = useAlert();
    const [formValues, setFormValues] = useState({
        changedQuantity: '',
        classification: '',
        eventCategory: '',
        eventName: '',
    });

    const DFUResolveHandle = async () =>{
        try{
            const formData = {...formValues,  DFU:showDFUGraph.dfu, SDATE:showDFUGraph.sdate}
            const result = await api.post("/api/dfu-history-corrected/", formData)
            getDFUCorrectedHistory()
        }
        catch(e){
            console.log(e);
        }
    }

    return (
        <div className='flex flex-col h-full overflow-y-scroll shadow-col scroll '>
                  {contextHolder}

            <table className='w-full whitespace-nowrap text-xs shadow-col  overflow-scroll'>
                <thead className='w-full sticky top-0 z-10 bg-slate-100 shadow-class shadow-col border'>
                    <tr className='font-bold whitespace-normal border shadow-col'>
                        {tableHeaders.map((item, index) => {
                            if (item === "LFU") {
                                return null; // Skip rendering for LFU
                            }
                            if(item === "Action"){
                                return (
                                    <td key={index} className='rounded-tl-sm bg-slate-100 text-center  px-3 shadow-col z-10  text-xs py-1 border-gray-200 w-min'>
                                        {item}
                                    </td>
                                )
                            }
                            if (item === "Actuals" || item === "Engine Resolved Quantity" || item === "User Resolved Quantity") {
                                return (
                                    <td key={index} className='rounded-tl-sm bg-slate-100 text-center  items-center px-3 shadow-col z-10  text-xs py-1  border-gray-200 w-min'>
                                        <div onClick={() => setsortDFUParam(item)} className={`flex gap-1 items-center cursor-pointer hover:text-indigo-500 ${sortDFUParam === item ? "text-indigo-500" : ""}`}>
                                        {/* <div className={`flex gap-1 items-center cursor-pointer hover:text-indigo-500`}> */}
                                            <p>{item}</p>
                                            <i><FaArrowDownLong /></i>
                                        </div>
                                    </td>
                                );
                            } else {
                                return (
                                    <td key={index} className='rounded-tl-sm bg-slate-100   px-3 shadow-col z-10  text-xs py-1 border-gray-200 w-min'>
                                        {item}
                                    </td>
                                );
                            }
                        })}
                    </tr>

                </thead>
                <tbody className='w-full'>
                    {
                        DFUTableData.length !== 0 && DFUTableData.map((item, index) => {
                            return(
                                <>
                                <tr>
                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>{item.DFU}</td>
                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>{item.SDATE}</td>
                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>{item.SQTY_LFU}</td>
                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>{item.SQTY_CORRECTED_ENGINE}</td>
                                    <td className={`px-3 ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} `}>{item.SQTY_CORRECTED_USER ? item.SQTY_CORRECTED_USER : 0}</td>
                                    <td className={`text-center px-3 py-2 text-xs ${showDFUGraph !== null && DFUGraphIndex === index && item.DFU === showDFUGraph.dfu ? "bg-indigo-200" : "bg-white"} text-blue-500 border border-gray-200 cursor-pointer`}>
                                        <Button onClick={() => handleDFUResolveButton(item, index)} className='p-0 text-xs h-0 ' type='link'>Resolve</Button>
                                    </td>
                                </tr>
                                {showDFUGraph !== null && showDFUGraph.dfu === item.DFU && DFUGraphIndex === index && (
                                    <tr className={`${true ? "bg-indigo-50" : ""}`}>
                                        <td colSpan={10000}>
                                            <div className='flex flex-row  gap-2 p-1 py-3 w-full h-full'>
                                                {/* <LineChart alertsGraphData={alertsGraphData} /> */}
                                                <DFUGraph/>
                                                <div className='flex flex-col justify-between w-1/2 space-y-4 bg-white px-5 py-4 rounded-xl box-shadow'>
                                                    <div className='flex flex-col space-y-4'>
                                                        <h2 className='text-xl font-semibold text-bodyTextBlue'>Training Opportunity for {showDFUGraph.sdate}</h2>
                                                        {/* <h5 className="text-slate-400">Current Selection shows 10343 units more than expected. The remaining unresolved quantity is 10361.</h5> */}
                                                        <DFUForm formValues={formValues} setFormValues={setFormValues}/>
                                                        {/* <DynamicForm changed_quantity={["Vendor Sales"]} changed_reason={["Low Sales"]} /> */}
                                                    </div>
                                                    <div className='flex flex-col space-y-4 w-full'>
                                                        <h3 className='font-semibold text-slate-400'>*The Resolution Quantity will be disaggregated based on underlying deviation quantities.</h3>
                                                        <div className="flex flex-row items-center justify-end py-2 w-full space-x-6">
                                                            <Button onClick={() => setShowDFUGraph(null)} type='link'>Cancel</Button>
                                                            <Button className='' type='primary' onClick={() => DFUResolveHandle()}>Resolve</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                                </>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

    )
}

export default DFUAlertsTable