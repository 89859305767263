import React from 'react';
import Chart from "chart.js/auto";

import { Bar } from 'react-chartjs-2';
import { useBase } from '../Layout/baseContext/BaseContext';
import { Button } from 'antd';
import graphLogo from '../assets/img/Screenshot 2024-09-09 162232.png'
import { usePlan } from '../Layout/baseContext/planningContext/PlanningContext';

const CombinedGraph = ({ fields, tdateVar, barData1, barData2, lineData1, lineData2, duration }) => {

  const { selectedMenu } = useBase();
  const { showLineGraph, setShowLineGraph, graphTypes } = useBase();
  const { setGraphEditPopUp } = usePlan();


  const budget = lineData1.datasets[0].data.map((item) => item === 0 ? null : item)
  const planner = lineData2.datasets[0].data.map((item) => item === 0 ? null : item)

  const sqty = barData1.datasets[0].data.map((item) => item === 0 ? null : item)
  const fqty_engine = barData2.datasets[0].data.map((item) => item === 0 ? null : item)

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    if (fields['interval'] === "FWEEK") {
      // Show full date in DD-MM-YY format
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear().toString().slice(-2); // Last 2 digits of the year
      return `${day}-${month}-${year}`; // e.g., '14-12-24'
  }
  
    
    if (fields['interval'] === "FMONTH") {
        // Show short month and two-digit year for FMonth
        const month = date.toLocaleString('default', { month: 'short' }); // e.g., 'Dec'
        const year = date.getFullYear().toString().slice(-2); // Get last 2 digits of year, e.g., '24'
        return `${month}-${year}`; // Format like 'Dec-24'
    }
    
    if (fields['interval'] === "FYEAR") {
        // Show only the last two digits of the year for FYear
        return date.getFullYear().toString(); // e.g., '24'
    }
    
    // Default return if interval type doesn't match
    return date.toLocaleDateString('en-US');
};


  const formattedDates = barData1.labels.map(formatDate);
  let data1;
  if (selectedMenu === 'supplyViewBase') {
    const engineArray = barData2.datasets[0].data.filter((item) => item !== null);
    const fqtyBudget = lineData1.datasets[0].data.filter((item) => item !== null);
    const fqtyUser = lineData2.datasets[0].data.filter((item) => item !== null);
    const labels = barData1.labels.filter((date) => new Date(date) >= new Date(tdateVar));
    const formattedDates = labels.map(formatDate);
    data1 = {
      labels: formattedDates,
      datasets: [
        {
          type: "line",
          label: "Budget",
          data: fqtyBudget,
          borderColor: "#34a853",
          borderWidth: 2,
          fill: false,
          pointStyle: 'circle',
          pointRadius: 3,
          pointBackgroundColor: '#34a853',
        },
        {
          type: "line",
          label: "Planner",
          data: fqtyUser,
          borderColor: "#ED7D31",
          borderWidth: 2,
          fill: false,
          pointStyle: 'circle',
          pointRadius: 3,
          pointBackgroundColor: '#ED7D31',
        },
        {
          type: showLineGraph ? "line" : "bar",  // Conditional rendering for Engine
          label: "Engine",
          data: engineArray,
          pointStyle: 'circle',
          pointRadius: 4,
          fill: false,
          pointBackgroundColor: '#4d8bf5',
          backgroundColor: showLineGraph ? "transparent" : "#4d8bf5",
          borderColor: showLineGraph ? "#4d8bf5" : undefined,
          borderWidth: showLineGraph ? 3 : undefined,
          barThickness: !showLineGraph ? 10 : undefined,
        },
      ],
    };
  } else {
    const labels = barData1.labels.filter((date) => new Date(date) >= new Date(tdateVar));
    const formatDates = labels.map(formatDate);
    data1 = {
      labels: selectedMenu === 'supplyViewBase' ? formatDates : formattedDates,
      datasets: [
        // Bar datasets
        {
          type: graphTypes.budget === "bar" ? "bar" : "line",
          label: "Budget",
          data: budget,
          pointStyle: 'circle',
          pointRadius: 3,
          fill: false,
          pointBackgroundColor: '#34a853',
          backgroundColor: graphTypes.budget === "bar" ? "#34a853" : "transparent",
          borderColor: graphTypes.budget === "line" ? "#34a853" : undefined,
          borderWidth: graphTypes.budget === "line" ? 2 : undefined,
          barThickness: graphTypes.budget === "bar" ? 10 : undefined,
        },
        {
          type: graphTypes.planner === "bar" ? "bar" : "line",
          label: "Planner",
          data: planner,
          pointStyle: 'circle',
          pointRadius: 3,
          fill: false,
          pointBackgroundColor: '#ED7D31',
          backgroundColor: graphTypes.planner === "bar" ? "#ED7D31" : "transparent",
          borderColor: graphTypes.planner === "line" ? "#ED7D31" : undefined,
          borderWidth: graphTypes.planner === "line" ? 2 : undefined,
          barThickness: graphTypes.planner === "bar" ? 10 : undefined,
        },
        {
          type: graphTypes.history === "bar" ? "bar" : "line",
          label: "History",
          data: sqty,
          pointStyle: 'circle',
          pointRadius: 3,
          fill: false,
          pointBackgroundColor: '#ffdb5c',
          backgroundColor: graphTypes.history === "bar" ? "#ffdb5c" : "transparent",
          borderColor: graphTypes.history === "line" ? "#ffdb5c" : undefined,
          borderWidth: graphTypes.history === "line" ? 2 : undefined,
          barThickness: graphTypes.history === "bar" ? 10 : undefined,
        },
        // Line datasets
        {
          type: graphTypes.engine === "bar" ? "bar" : "line",
          label: "Engine",
          data: fqty_engine,
          pointStyle: 'circle',
          pointRadius: 4,
          fill: false,
          pointBackgroundColor: '#4d8bf5',
          backgroundColor: graphTypes.engine === "bar" ? "#4d8bf5" : "transparent",
          borderColor: graphTypes.engine === "line" ? "#4d8bf5" : undefined,
          borderWidth: graphTypes.engine === "line" ? 3 : undefined,
          barThickness: graphTypes.engine === "bar" ? 10 : undefined,
        },
      ],
    };


  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return Intl.NumberFormat('en-US', {
              notation: "compact",
              maximumFractionDigits: 1
            }).format(value);
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="flex flex-col bg-white items-center w-full h-[200px]">
      <div className="flex flex-row items-center justify-between w-full -mt-4">
        <div className='flex items-center gap-2  mb-2 '>
          <img onClick={() => setGraphEditPopUp(true)} className='aspect-[4/3] hover:opacity-70 cursor-pointer' src={graphLogo} width={30} height={20} alt="" />
          {/* <p onClick={() => setShowLineGraph(false)} className={`${showLineGraph ? "":"bg-blue-500 rounded-full text-white "} px-2 w-12 text-center cursor-pointer`}>Bar</p>
          <p onClick={() => setShowLineGraph(true)} className={`${showLineGraph ? "bg-blue-500 rounded-full text-white":""} px-2 w-12   text-center  cursor-pointer`}>Line</p> */}
          {/* <Button onClick={() => setShowLineGraph(true)} className='!p-0 !m-0 w-14 h-5 !border !border-blue-500 !rounded-full' type='link'>Line</Button>
          <Button onClick={() => setShowLineGraph(false)} className='!p-0 !m-0 w-14 h-5 !border !border-blue-500 !rounded-full' type='link'>Bar</Button> */}
        </div>
        <div className='flex items-center gap-4'>
          <div className="flex flex-row items-center space-x-2 justify-between">
            <div className='h-4 w-12 bg-[#4489e2] rounded-full' />
            <p>Engine</p>
          </div>
          {selectedMenu !== 'supplyView' &&
            <div className="flex flex-row items-center space-x-2 justify-between">
              <div className='h-4 w-12 bg-[#ffdb5c] rounded-full' />
              <p>History</p>
            </div>
          }
          <div className="flex flex-row items-center space-x-2 justify-between">
            <div className='h-4 w-12 bg-[#ed7d31] rounded-full' />
            <p>Planner</p>
          </div>
          <div className="flex flex-row items-center space-x-2 justify-between">
            <div className='h-4 w-12 bg-[#34a853] rounded-full' />
            <p>Budget</p>
          </div>
        </div>
      </div>
      {duration === 'FWEEK' && <Bar data={data1} options={options} />}
      {duration === 'FMONTH' && <Bar data={data1} options={options} />}
      {duration === 'FYEAR' && <Bar data={data1} options={options} />}
    </div>
  );
};

export default CombinedGraph;
