import React, { useState } from 'react'
import UploadTab from './components/uploadData/UploadTab'
import { Layout } from 'antd'
import HeaderComponent from '../Layout/Header/HeaderComponent'
import { Content } from 'rsuite'
import { useUpload } from '../Layout/baseContext/uploadContext/UploadContext'
import ParamsTab from './components/toolParams/ParamsTab'
import UIMapping from './components/uimapping/UIMapping'
import DemandScan from './components/demandscan/DemandScan'


const DataSection = ({
  locationFilter,
  productFilter,
  customerFilter,
  uiMappingData,
  filterString,
  setFilterString,
}) => {
  const {selectedArea, setSelectedArea} = useUpload()
  

  return (
    <Layout className="h-full " style={{ background: "#F9F9FC" }}>
    <header
      className="bg-[#f9f9fc] w-full h-full z-30"
    >
      {
        // Object.keys(locationFilter).length > 0 && Object.keys(productFilter).length > 0 && Object.keys(customerFilter).length > 0
        //   ?
          <HeaderComponent selectedArea={selectedArea} uiMappingData={uiMappingData} filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilter} productFilters={productFilter} customerFilters={customerFilter} setSelectedArea={setSelectedArea} /> 
          // : null
      }

    </header>
    <Content
      className="content w-full h-full "
    >

      {
        selectedArea === "uploadTab" &&
          
          <UploadTab /> 
      }
      {
        selectedArea === "parameter" &&
          
          <ParamsTab /> 
      }
      {
        selectedArea === "uiMapping" &&
          
          <UIMapping /> 
      }
      {
        selectedArea === "demandScan" &&
          
          <DemandScan /> 
      }
    </Content>
  </Layout>
  )
}

export default DataSection