import { createContext, useContext, useState, useEffect } from "react";
import table1Data from "../../../../data/alertsTable1.json"
import api from "../../../../api";
import { message } from "antd";
import { useBase } from "../BaseContext";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const areaOptions = ["Data Issues", "New Exceptions", "Last Period Exceptions", "Current Year", "Older Exceptions", "Partially Resolved", "Resolved Exceptions"];
    const tableHeaders = [
        "",
        "DFU",
        "Date",
        "LFU",
        // "Classification based on",
        // "Alert Type",
        // "Quantity Above Average",
        // "Quantity Above Seasonal Average",
        "Actuals",
        "Engine Resolved Quantity",
        "User Resolved Quantity",
        // "Event category",
        // "Event name",
        "Action"
    ];

    const { fields } = useBase();


    const [selectedArea, setSelectedArea] = useState("New Exceptions");
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [selectedTable, setSelectedTable] = useState(table1Data);
    const [alertsData, setAlertsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [alertsGraphData, setalertsGraphData] = useState(null)
    const [highlightDate, setHighlightDate] = useState(null)
    const [sortingParameter, setSortingParameter] = useState('Engine Resolved Quantity')
    const [updatedRecords, setUpdatedRecords] = useState([]);
    const [unResPop, setunResPop] = useState(false)
    const [LFUResolvePop, setLFUResolvePop] = useState(false)

    const [expandedRow, setExpandedRow] = useState(null);
    const [expandType, setExpandType] = useState(null);
    const [lfuExpandedIndex, setLfuExpandedIndex] = useState(null)
    const [editableLFU, setEditableLFU] = useState(null);
    const [editableSDATE, setEditableSDATE] = useState(null);
    const [editableValue, setEditableValue] = useState("");
    const [editedLFUs, setEditedLFUs] = useState([]);
    const [comboExpand, setComboExpand] = useState(false);
    const [rowSpan, setRowSpan] = useState(0);

    const [editingDFUIndex, seteditingDFUIndex] = useState(null); // Data object being edited
    const [editedRecords, setEditedRecords] = useState([]); // Edited records


    //new states
    const [currentLFUs, setcurrentLFUs] = useState([])
    const [originalAlertsData, setOriginalAlertsData] = useState([])
    const [orderOfSorting, setorderOfSorting] = useState('descending')
    const [lfuLoading, setLfuLoading] = useState(false)
    const [alertGraphLoading, setalertGraphLoading] = useState(false)


    //DFU corrected History states
    const [showDFUonly, setShowDFUonly] = useState(false)   //it will be fetched from param table
    const [DFUFullData, setDFUFullData] = useState([])
    const [DFUTableData, setDFUTableData] = useState([])
    const [sortDFUParam, setsortDFUParam] = useState("Engine Resolved Quantity")
    const [DFUsortingOrder, setDFUsortingOrder] = useState("descending")
    const [showDFUGraph, setShowDFUGraph] = useState(null);
    const [DFUGraphIndex, setDFUGraphIndex] = useState(null)

    // Utility function to sort data
    const sortData = (data, sortingParameter, orderOfSorting) => {
        var sortParam;
        if (sortingParameter === 'Engine Resolved Quantity') {
            sortParam = 'engine_resolved_quantity'
        }
        if (sortingParameter === 'Actuals') {
            sortParam = 'sqty_lfu'
        } if (sortingParameter === 'User Resolved Quantity') {
            sortParam = 'sqty_corrected_user'
        }
        // Sort the data based on sortingParameter and orderOfSorting
        const sortedData = [...data].sort((a, b) => {
            if (orderOfSorting === 'ascending') {
                return a[sortParam] > b[sortParam] ? 1 : -1;
            } else if (orderOfSorting === 'descending') {
                return a[sortParam] < b[sortParam] ? 1 : -1;
            } else {
                console.error(`Invalid sorting order: ${orderOfSorting}`);
                return 0;
            }
        });

        // Return only the first 1000 records
        return sortedData.slice(0, 500);
    };

    const filterDataBySelectedArea = (data, selectedArea) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Get current month (1-12)
        const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Previous month
        const lastPeriodMonth = currentMonth <= 2 ? 12 + (currentMonth - 2) : currentMonth - 2; // Month before previous month        
        const currentYear = currentDate.getFullYear(); // Get current year
        const previousMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear; // Adjust year for previous month

        if (selectedArea === "Partially Resolved") {
            return data.filter(item =>
                item.sqty_corrected_user > 0 &&
                item.sqty_corrected_user < item.engine_resolved_quantity
            );
        }

        if (selectedArea === "Resolved Exceptions") {
            return data.filter(item =>
                item.sqty_corrected_user >= item.engine_resolved_quantity
            );
        }

        if (selectedArea === "New Exceptions") {
            return data.filter(item => {
                const itemDate = new Date(item.SDATE);
                return itemDate.getMonth() + 1 === previousMonth &&
                    itemDate.getFullYear() === previousMonthYear;
            });
        }

        if (selectedArea === "Last Period Exceptions") {
            return data.filter(item => {
                const itemDate = new Date(item.SDATE);
                return itemDate.getMonth() + 1 === lastPeriodMonth &&
                    itemDate.getFullYear() === previousMonthYear;
            });
        }

        if (selectedArea === "Current Year") {
            return data.filter(item => {
                const itemDate = new Date(item.SDATE);
                return itemDate.getFullYear() === currentYear;
            });
        }

        if (selectedArea === "Older Exceptions") {
            return data.filter(item => {
                const itemDate = new Date(item.SDATE);
                return itemDate.getFullYear() < currentYear;
            });
        }

        return data; // Return all data if selectedArea is not matched
    };




    const fetchAlerts = async () => {
        try {
            setLoading(true)
            const alertsDataOriginal = await api.get("/api/alertsDFU/");

            // const allData = response.data.results;
            setOriginalAlertsData(alertsDataOriginal.data) // Store all records

            const filteredData = filterDataBySelectedArea(alertsDataOriginal.data, selectedArea)
            const sortedData = sortData(filteredData, sortingParameter, orderOfSorting);
            setAlertsData(sortedData);
            setSelectedTable(sortedData); // Store unique records
            // const uniqueData = getUniqueRecordsByDateAndDFU(allData);
            // const sortedData = sortDataByParam(uniqueData, sortingParameter);
            // console.log(sortedData);

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };


    const getLFUs = async (sdate, dfu, forDFUClick = false) => {
        if (!forDFUClick) {
            setcurrentLFUs([])
        }
        setLfuLoading(true)
        try {
            const LFU = await api.get('/api/getLFU/', {
                params: {
                    SDATE: sdate,
                    DFU: dfu
                }
            });
            if (forDFUClick) {
                setLfuLoading(false)
                return LFU;
            }
            else {
                setcurrentLFUs(LFU.data)
            }
        }
        catch (e) {
            console.log(e);
        }
        setLfuLoading(false)
    }

    function formatString(arr) {
        const items = [];
        const locations = [];
        const customers = [];

        arr.forEach(entry => {
            const [item, location, customer] = entry.split('[_]');
            items.push(item);
            locations.push(location);
            customers.push(customer);
        });

        const itemString = `&ITEM=${items.join(',')}`;
        const locationString = `&LOCATION=${locations.join(',')}`;
        const customerString = `&CUSTOMER=${customers.join(',').replace(/ /g, '%20')}`;

        return itemString + locationString + customerString;
    }
    // Function to transform the data format and remove entries with null SQTY
    function transformData(data) {
        return data
            .filter(item => item.SQTY !== null)  // Filter out items where SQTY is null
            .map(item => ({
                SDATE: item.FMONTH,
                sqty_lfu: item.SQTY
            }));
    }


    const getAlertGraph = async (lfu) => {
        const lfuSplitted = lfu.split("[_]");

        var fstring;
        if (editedLFUs.length !== 0) {
            fstring = formatString(editedLFUs);
        }
        else {
            fstring = `&ITEM=${lfuSplitted[0]}&LOCATION=${lfuSplitted[1]}&CUSTOMER=${lfuSplitted[2]}`;
        }


        setalertGraphLoading(true)
        try {
            const param = editedLFUs.length !== 0 ? editedLFUs.join(',') : lfu
            // const lfuGraph = await api.get('/api/getAlertGraph/', {
            //     params: {
            //         LFU: param,
            //     }
            // });
            // setalertsGraphData(lfuGraph.data)

            const lfuGraph = await api.get("/api/forecastcube/?fields=" + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "&page=1" + fstring);
            const transformedData = transformData(lfuGraph.data.results);
            setalertsGraphData(transformedData)

        }
        catch (e) {
            console.log(e);
        }
        setalertGraphLoading(false)
    }


    const getAlertGraphDFU = async (dfu, sdate) => {

        try {

            const dfuGraph = await api.get('/api/getAlertGraphDFU/', {
                params: {
                    DFU: dfu,
                    SDATE: sdate
                }
            });
            setalertsGraphData(dfuGraph.data);
        }
        catch (e) {
            console.log(e);
        }
    }


    const handleDiscardChanges = () => {
        setAlertsData(originalAlertsData);
        // const uniqueData = getUniqueRecordsByDateAndDFU(originalAlertsData);
        const sortedData = sortData(originalAlertsData, sortingParameter, orderOfSorting);
        setSelectedTable(sortedData); // Store unique records
        setUpdatedRecords([]);
        setRowSpan(0);
        setIsExpanded(false);
        setLfuExpandedIndex(null);
        setComboExpand(false)
        setEditedLFUs([]);

    }
    const sendUpdatedRecords = async () => {
        if (updatedRecords.length === 0) {
            message.info("Nothing to resolve");
        }
        try {
            const response = await api.post('/api/editAlert/', updatedRecords);
            message.success("Resolved Successfully");
            setUpdatedRecords([]);
            fetchAlerts();
        } catch (error) {
            message.error("Something went wrong")
            console.error('Error sending data:', error.response ? error.response.data : error.message);
        }
    };


    const DFUResolve = async () => {
        if (editedRecords.length === 0) {
            message.info("Nothing to resolve");
        }
        try {
            const response = await api.post('/api/editAlertDFU/', editedRecords);
            message.success("DFU Resolved Successfully");
            setEditedRecords([]);
            fetchAlerts();
        } catch (error) {
            message.error("Something went wrong")
            console.error('Error sending data:', error.response ? error.response.data : error.message);
        }
    }




    //DFU

    
    const filterDFUDataBySelectedArea = (data, selectedArea) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Get current month (1-12)
        const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1; // Previous month
        const lastPeriodMonth = currentMonth <= 2 ? 12 + (currentMonth - 2) : currentMonth - 2; // Month before previous month        
        const currentYear = currentDate.getFullYear(); // Get current year
        const previousMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear; // Adjust year for previous month
        if (selectedArea === "Partially Resolved") {
            return data.filter(item =>
                item.SQTY_CORRECTED_USER > 0 &&
                item.SQTY_CORRECTED_USER < item.SQTY_CORRECTED_ENGINE
            );
        }
    
        if (selectedArea === "Resolved Exceptions") {
            return data.filter(item =>
                item.SQTY_CORRECTED_USER !==  null &&
                item.SQTY_CORRECTED_USER >= item.SQTY_CORRECTED_ENGINE
            );
        }
    
        if (selectedArea === "New Exceptions") {
            return data.filter(item => {
                const itemDate = new Date(item.SDATE);
                return itemDate.getMonth() + 1 === previousMonth &&
                    itemDate.getFullYear() === previousMonthYear;
            });
        }
    
        if (selectedArea === "Last Period Exceptions") {
            return data.filter(item => {
                const itemDate = new Date(item.SDATE);
                return itemDate.getMonth() + 1 === lastPeriodMonth &&
                    itemDate.getFullYear() === previousMonthYear;
            });
        }
    
        if (selectedArea === "Current Year") {
            return data.filter(item => {
                const itemDate = new Date(item.SDATE);
                return itemDate.getFullYear() === currentYear;
            });
        }
    
        if (selectedArea === "Older Exceptions") {
            return data.filter(item => {
                const itemDate = new Date(item.SDATE);
                return itemDate.getFullYear() < currentYear;
            });
        }
    
        return data; // Return all data if selectedArea is not matched
    };
    

    const sortDFUtableData  = ( data, sortParam, orderOfSorting) => {
        var sortParam;
        if (sortDFUParam === 'Engine Resolved Quantity') {
            sortParam = 'SQTY_CORRECTED_ENGINE'
        }
        if (sortDFUParam === 'Actuals') {
            sortParam = 'SQTY_LFU'
        } if (sortDFUParam === 'User Resolved Quantity') {
            sortParam = 'SQTY_CORRECTED_USER'
        }
        // Sort the data based on sortDFUParam and orderOfSorting
        const sortedData = [...data].sort((a, b) => {
            if (orderOfSorting === 'ascending') {
                return a[sortParam] > b[sortParam] ? 1 : -1;
            } else if (orderOfSorting === 'descending') {
                return a[sortParam] < b[sortParam] ? 1 : -1;
            } else {
                console.error(`Invalid sorting order: ${orderOfSorting}`);
                return 0;
            }
        });

        // Return only the first 1000 records
        return sortedData;
    }
    const getDFUCorrectedHistory = async () => {
        setLoading(true)
        try {
            const result = await api.get("/api/dfu-history-corrected/");
            setDFUFullData(result.data)
            const tableData = filterDFUDataBySelectedArea(result.data, selectedArea)
            const sortedData = sortDFUtableData(tableData, sortDFUParam, DFUsortingOrder)
            setDFUTableData(sortedData)
        }
        catch (e) {
            console.log(e);
        }
        setLoading(false)
    }

    const handleDFUResolveButton = (item, index) => {
        if(showDFUGraph !== null && item.SDATE === showDFUGraph.sdate && item.DFU === showDFUGraph.dfu){
            setShowDFUGraph(null)
            setDFUGraphIndex(null)
        }
        else{
            setShowDFUGraph({dfu:item.DFU, sdate: item.SDATE})
            setDFUGraphIndex(index)

        }
    }

    useEffect(() => {
        // this is for LFU
        if (selectedTable.length > 0) {
            const sortedData = sortData(selectedTable, sortingParameter, orderOfSorting);
            setAlertsData(sortedData);
            setSelectedTable(sortedData)
        }



    }, [sortingParameter])

    useEffect(() => {
        // this is for LFU  
        const filteredData = filterDataBySelectedArea(originalAlertsData, selectedArea)
        const sortedData = sortData(filteredData, sortingParameter, orderOfSorting);
        setAlertsData(sortedData);
        setSelectedTable(sortedData);

        // const tableData = filterDFUDataBySelectedArea(DFUFullData, selectedArea)
        // const sortedData = sortDFUtableData(tableData, sortDFUParam, DFUsortingOrder)
        // console.log(tableData);
        // setDFUTableData(sortedData)

    }, [selectedArea])

    useEffect(() => {
        const sortedData = sortDFUtableData(DFUTableData, sortDFUParam, DFUsortingOrder)
        setDFUTableData(sortedData)
    },[sortDFUParam])

    useEffect(() => {
        //this is for LFU
        fetchAlerts();

        // getDFUCorrectedHistory();
    }, []);


    return (
        <AlertContext.Provider
            value={{
                tableHeaders,
                alertsData,
                setAlertsData,
                loading,
                error,
                selectedArea,
                setSelectedArea,
                isExpanded,
                setIsExpanded,
                selectedIndex,
                setSelectedIndex,
                selectedTable,
                setSelectedTable,
                areaOptions,
                alertsGraphData,
                setalertsGraphData,
                highlightDate, setHighlightDate,
                sortData,
                sortingParameter, setSortingParameter,
                updatedRecords, setUpdatedRecords,
                sendUpdatedRecords,
                unResPop, setunResPop,
                expandedRow, setExpandedRow,
                expandType, setExpandType,
                lfuExpandedIndex, setLfuExpandedIndex,
                editableLFU, setEditableLFU,
                editableSDATE, setEditableSDATE,
                editableValue, setEditableValue,
                editedLFUs, setEditedLFUs,
                comboExpand, setComboExpand,
                rowSpan, setRowSpan,
                LFUResolvePop, setLFUResolvePop,
                editingDFUIndex, seteditingDFUIndex,
                editedRecords, setEditedRecords,
                DFUResolve,

                currentLFUs,
                setcurrentLFUs,
                getLFUs,
                lfuLoading,
                originalAlertsData,
                getAlertGraph,
                alertGraphLoading,
                handleDiscardChanges,
                getAlertGraphDFU,


                //DFU
                DFUFullData,
                showDFUonly, setShowDFUonly,
                DFUTableData, setDFUTableData,
                sortDFUParam,setsortDFUParam,
                showDFUGraph, setShowDFUGraph,
                handleDFUResolveButton,
                DFUGraphIndex, setDFUGraphIndex,
                getDFUCorrectedHistory
            }}>
            {children}
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);
