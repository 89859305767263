import axios from "axios";
import { ACCESS_TOKEN, ORG_ID, SUB_ORG_ID } from "./constants";

const apiUrl = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL: apiUrl
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const orgId = localStorage.getItem("ORG_ID"); 
    // const suborgId = localStorage.getItem(SUB_ORG_ID); 

    // if(!orgId){
    //   return 
    // }

    // Add the token to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Add ORG_ID to the request headers
    // if (orgId) {
      config.headers["ORG-ID"] = orgId; // Custom header for org_id
      // config.headers["SUB_ORG-ID"] = null; // Custom header for org_id
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
