import React, { useRef, useState, useEffect } from 'react';
import { usePlan } from './Layout/baseContext/planningContext/PlanningContext';
import { useBase } from './Layout/baseContext/BaseContext';

const FilterButton = ({ children, setFilterString, filterString, locationFilters, customerFilters, productFilters, uiMappingData }) => {


  const filterSections = ["Product Master", "Location Master", "Customer Master"]
  const [selectedSection, setSelectedSection] = useState(0);
  const [subSelections, setSubSelections] = useState([]);
  const [subSelection, setSubSelection] = useState("id");

  const {
    subSelectionFilterMap, setSubSelectionFilterMap,
    checkBoxArray, setCheckBoxArray,
    showOverlay, setShowOverlay,
    submitFilters,
    clearAllFilters
  } = usePlan();

  const { filters, setFilters } = useBase()

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");


  useEffect(() => {
    if (Object.keys(productFilters).length === 0 || Object.keys(customerFilters).length === 0 || Object.keys(locationFilters).length === 0) {
      setLoading(true);
    }
    else {
      setSubSelections([{
        "parent": "Product Master",
        "selectors": Object.keys(productFilters[0])
      },
      {
        "parent": "Location Master",
        "selectors": Object.keys(locationFilters[0])
      },
      {
        "parent": "Customer Master",
        "selectors": Object.keys(customerFilters[0])
      }])

      setLoading(false);
    }
  }, [loading])
  const buttonRef = useRef(null);





  useEffect(() => {
    if (filterString === "") {
      clearAllFilters();
    }
  }, [filterString])
  const displaySelectedFilters = () => {
    return [...filters.entries()].map(([key, value], index) => (
      <div key={index} className="flex items-center pr-2 border-r border-black">
        <span className="font-bold">{uiMappingData[0][key]}:</span>
        <span className="ml-2">{value.join(', ')}</span>
      </div>
    ));
  };

  const toggleOverlay = () => {

    setShowOverlay(!showOverlay);

  };


  useEffect(() => {
    var newMap = new Map();

    if (Object.keys(productFilters).length !== 0 && Object.keys(customerFilters).length !== 0 && Object.keys(locationFilters).length !== 0) {

      if (selectedSection === 0) {
        Object.keys(productFilters[0]).map((item) => {
          newMap.set(item, Array.from(new Set(productFilters.map((data) => data[item]))));
        })
      }
      else if (selectedSection === 1) {
        Object.keys(locationFilters[0]).map((item) => {
          newMap.set(item, Array.from(new Set(locationFilters.map((data) => data[item]))));
        })
      }
      else if (selectedSection === 2) {
        Object.keys(customerFilters[0]).map((item) => {
          newMap.set(item, Array.from(new Set(customerFilters.map((data) => data[item]))));
        })
      }

      setSubSelectionFilterMap(newMap);

      setSubSelection(newMap.get([...newMap.keys()][0]))
    }

  }, [selectedSection])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        !event.target.closest(".filter-overlay")
      ) {
        setShowOverlay(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef]);


  return (
    <div className=" flex items-center z-50 text-bodyTextBlue" style={{ zIndex: 200 }}>
      <button
        ref={buttonRef}
        className={`bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 text-sm shadow-md rounded-full`}
        onClick={toggleOverlay}
      >
        {children}
      </button>
      {showOverlay ? (

        <div
          className="fixed mt-3 mr-20 bg-white p-4 w-10/12 h-[500px] rounded-lg shadow-lg filter-overlay z-100"
          style={{
            top: buttonRef.current.offsetTop + buttonRef.current.offsetHeight,
            right: 0,
            left: 'auto',
          }}
        >
          <div className='flex flex-col space-y-2 justify-between h-full'>
            <h3 className='text-lg font-semibold '>Filters</h3>
            <input
              type="text"
              placeholder="Search filters..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 py-3 rounded-lg text-sm focus:outline-none"
            />
            <div className="flex flex-row   border-b-gray-200 border-b items-center space-x-2 justify-start">
              {filterSections.map((item, index) => {
                return (
                  <div
                    className={` flex flex-row py-2 space-x-2 hover:text-blue-500 cursor-pointer items-center px-2 ${index === selectedSection ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
                    onClick={() => {
                      setSelectedSection(index)
                    }}
                  >
                    {/* <div className={`w-4 h-4 rounded-md  ${selectedSection === index ? 'border border-blue-500' : 'border border-gray-300'} `}></div> */}
                    <div className=' text-sm font-semibold'>{item}</div>
                  </div>)

              })}

            </div>
            <div className='flex flex-col h-full'>
              <p className='font-semibold text-gray-600 border-b border-gray-200 pb-2 pl-2'>Select filters to apply</p>
              <div className='flex items-start h-full'>
                <div className='w-1/5  relative h-full '>
                  <div className='absolute overflow-y-scroll h-full w-full'>

                    <div className=' h-full flex flex-col border-r border-r-gray-200 overflow-y-scroll overflow-x-hidden scroll'>
                      {subSelections.map((item, index) => {
                        if (item["parent"] === filterSections[selectedSection]) {

                          return item["selectors"]

                            .map((name) => {

                              return (
                                <div
                                  className={`flex flex-row  m-0 px-2 py-2 border-b justify-between hover:bg-slate-100  items-center text-xs w-full cursor-pointer rounded-md ${subSelection === name ? 'bg-slate-200' : ''}`}
                                  onClick={() => setSubSelection(name)}
                                >

                                  <h3 className="font-bold ">{uiMappingData[0][name]}</h3>

                                </div>
                              );
                            });
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className='w-4/5 flex  flex-col p-2  space-y-2 h-full'>
                  <h5 className='text-sm flex gap-2'>
                    {/* <span>{checkBoxArray.length} </span>
                    <p>
                      Total filters selected
                    </p> */}
                    <div className="flex">
                      <input
                        id="select-all"
                        type="checkbox"
                        onChange={(e) => {
                          const isChecked = e.target.checked;

                          // Get the currently visible items based on the search query
                          const visibleItems =
                            subSelectionFilterMap.get(subSelection)?.filter((item) =>
                            item !== null && item.toLowerCase().includes(searchQuery.toLowerCase())
                            ) || [];

                          if (isChecked) {
                            // Add all visible items to filters and checkBoxArray
                            setFilters((prevFilters) => {
                              const updatedFilters = new Map(prevFilters);
                              const currentSelection = updatedFilters.get(subSelection) || [];
                              updatedFilters.set(
                                subSelection,
                                Array.from(new Set([...currentSelection, ...visibleItems]))
                              );
                              return updatedFilters;
                            });

                            setCheckBoxArray((prevArray) =>
                              Array.from(new Set([...prevArray, ...visibleItems]))
                            );
                          } else {
                            // Remove all visible items from filters and checkBoxArray
                            setFilters((prevFilters) => {
                              const updatedFilters = new Map(prevFilters);
                              const currentSelection = updatedFilters.get(subSelection) || [];
                              updatedFilters.set(
                                subSelection,
                                currentSelection.filter((item) => !visibleItems.includes(item))
                              );
                              return updatedFilters;
                            });

                            setCheckBoxArray((prevArray) =>
                              prevArray.filter((item) => !visibleItems.includes(item))
                            );
                          }
                        }}
                        checked={
                          // Check if all visible items are selected
                          [...subSelectionFilterMap.keys()].includes(subSelection) && subSelectionFilterMap
                            .get(subSelection)
                            .filter((item) =>
                            item !== null && item.toLowerCase().includes(searchQuery.toLowerCase())
                            )
                            ?.every((item) => checkBoxArray.includes(item)) || false
                        }
                      />
                      <label htmlFor="select-all" className="cursor-pointer font-semibold">
                        Select All
                      </label>
                    </div>

                  </h5>
                  <div className='relative h-full w-full'>

                    <div className='absolute overflow-y-scroll scroll h-full w-full'>

                      <div className='grid grid-cols-3 justify-start items-start h-full w-full '>
                        {
                          [...subSelectionFilterMap.keys()].includes(subSelection) && subSelectionFilterMap.get(subSelection).map((item, index) => {
                            if (item !== null && item.toLowerCase().includes(searchQuery.toLowerCase())) {
                              return (<div className='flex flex-row  text-gray-700  '>
                                <input
                                  id={item}
                                  key={index}
                                  type='checkbox'
                                  onChange={(e) => {
                                    var updatedMap;
                                    var arr;
                                    if (e.target.checked) {
                                      updatedMap = filters;
                                      arr = filters.get(subSelection) ? filters.get(subSelection) : [];
                                      arr.push(item);
                                      updatedMap.set(subSelection, arr);
                                      setCheckBoxArray((prevState) => [...prevState, item]);
                                      setFilters(updatedMap);
                                    } else {
                                      updatedMap = filters;
                                      arr = filters.get(subSelection) ? filters.get(subSelection) : [];
                                      var newArr = arr.filter((data) => data !== item);
                                      updatedMap.set(subSelection, newArr);
                                      setCheckBoxArray((prevState) => prevState.filter((data) => data !== item));
                                      setFilters(updatedMap);
                                    }
                                  }}
                                  checked={checkBoxArray.includes(item)}
                                  className='cursor-pointer'
                                /><label htmlFor={item} className='cursor-pointer'>{item}</label>
                              </div>
                              )
                            }
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-2 border-t border-t-gray-200">
              <h3 className="text-lg font-semibold ">Selected Filters</h3>


              <div className="flex flex-row space-x-2 items-center max-h-16 overflow-y-scroll scroll">
                {displaySelectedFilters()}
              </div>


            </div>
            <div className='flex flex-row items-center space-x-4 justify-end w-full p-2 '>
              <button className="px-12 py-2 font-semibold border border-red-500 hover:bg-red-50 rounded-md text-red-500" onClick={clearAllFilters}>Clear All</button>
              <button className='px-12 py-2 font-semibold border bg-slate-400 hover:bg-slate-500 rounded-md text-white' onClick={toggleOverlay}>Cancel</button>
              <button className='px-12 py-2 font-semibold bg-blue-500 hover:bg-blue-600 rounded-md text-white' onClick={submitFilters}>Apply</button>
            </div>
          </div>
        </div>) : null
      }

    </div>
  );
};

export default FilterButton;
