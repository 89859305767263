import React, { useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import { useAlert } from '../../Layout/baseContext/alertsContext/AlertsContext';

const { Option } = Select;

const DFUForm = ({formValues,setFormValues}) => {
    const { showDFUGraph } = useAlert();

    // Update the parent state whenever form values change
    // useEffect(() => {
    //     setFormData(formValues);
    // }, [formValues, setFormData]);

    // Handle input changes
    const handleChange = (field, value) => {
        setFormValues(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    return (
        <div className='grid grid-cols-2 gap-2 text-bodyTextBlue'>
            {/* Changed Quantity Input (numeric) */}
            <div className = "flex flex-col">
                <label className='mb-1'>Changed Quantity*</label>
                <Input
                    placeholder="Changed Quantity"
                    type="number"
                    value={formValues.changedQuantity}
                    onChange={(e) => handleChange('changedQuantity', e.target.value)}
                    required
                />
            </div>

            {/* Classification Input */}
            <div className = "flex flex-col">
                <label className='mb-1'>Classification</label>
                <Input
                    placeholder="Classification"
                    value={formValues.classification}
                    onChange={(e) => handleChange('classification', e.target.value)}
                />
            </div>

            {/* Event Category Dropdown */}
            <div className = "flex flex-col">
                <label className='mb-1'>Select category</label>
                <Select
                    placeholder="Select category"
                    value={formValues.eventCategory}
                    onChange={(value) => handleChange('eventCategory', value)}
                >
                    <Option value="category1">Category 1</Option>
                    <Option value="category2">Category 2</Option>
                    <Option value="category3">Category 3</Option>
                </Select>
            </div>

            {/* Event Name Input */}
            <div className = "flex flex-col">
                <label className='mb-1'>Event Name</label>
                <Input
                    placeholder="Event Name"
                    value={formValues.eventName}
                    onChange={(e) => handleChange('eventName', e.target.value)}
                />
            </div>

        </div>
    );
};

export default DFUForm;
