import React, { useEffect, useState } from "react";
import { Layout, Menu, message } from "antd";
import Performance from "../Performance/Performance";
import HeaderComponent from "./Header/HeaderComponent";
import "../../css/sidebar.css";
import "../../css/baselayout.css";
import horizonLogo from "../assets/img/noBgLogo.png"
import Methods from "../Methods";
import Alerts from "../alerts/Alerts";
import LoadingOverlay from "../LoadingOverlay";
import axios from "axios";
import PlanningOverview from "../PlanningOverview/PlanningOverview";
import PostsContainer from "../posts/PostsContainer";
import PortfolioContainer from "../portfolio/PortfolioContainer";
import DashboardContainer from "../dashboard/DashboardContainer";
import QuesContainer from "../ques/QuesContainer"
import DataSection from "../dataSection/DataSection";
import { useBase } from "./baseContext/BaseContext";
import DiagramContainer from "../diagram/DiagramContainer";
import Snop from "../snop/Snop";
import SupplyViewBase from "../supplyView/SupplyViewBase";
import InventoryReviewBase from "../inventoryReview/InventoryReviewBase";
import { usePlan } from "./baseContext/planningContext/PlanningContext";
import api from "../../api";

const { Content, Sider } = Layout;


const menuItemStyle = {
  background: "#ffffff",
  borderRadius: 40,
};

const BaseLayout = () => {
  const { selectedMenu, setSelectedMenu,
    locationFilter, setLocationFilter,
    customerFilter, setCustomerFilter,
    productFilter, setProductFilter,
    fields, setFields,
    filterString, setFilterString,
    graphData, setGraphData,
    startDate, setStartDate,
    next, setNext,
    uiMappingData, setUimappingData, params, setParams,
    perfPage, setPerfPage,
    page, setPage,
    additionalFields, setAdditionalFields,
    columnsShown, setColumnShown,
    canFetchPlan, setcanFetchPlan,
    handleRefresh, orgType
  } = useBase()
  const { selectedArea, setSelectedArea } = usePlan()
  const [loading, setLoading] = useState(false);
  const [adjLoading, setAdjLoading] = useState(false)



  const getUiMapping = async () => {
    const cachedUiMapping = localStorage.getItem("uiMapping");
    if (cachedUiMapping) {
      setUimappingData(JSON.parse(cachedUiMapping));
    } else {
      const response = await api.get("/api/uimapping/");
      setUimappingData(response.data);
      localStorage.setItem("uiMapping", JSON.stringify(response.data));
    }
  };

  const getFilterItems = async () => {
    try {
      setLoading(true);

      const item_array = [];
      const location_array = [];
      const customer_array = [];

      Object.keys(uiMappingData[0]).forEach((i) => {
        if (i !== "id" && i.substring(0, 1) === "I" && uiMappingData[0][i] !== null) {
          item_array.push(i);
        } else if (i !== "id" && i.substring(0, 1) === "L" && uiMappingData[0][i] !== null) {
          location_array.push(i);
        } else if (i !== "id" && i.substring(0, 1) === "C" && uiMappingData[0][i] !== null) {
          customer_array.push(i);
        }
      });

      const item_string = item_array.join(",");
      const location_string = location_array.join(",");
      const customer_string = customer_array.join(",");


      const locationFilters = await api.get(`/api/locations/?fields=${location_string}`);
      const locationFiltersData = locationFilters.data;
      setLocationFilter(locationFiltersData);

      const customerFilters = await api.get(`/api/customers/?fields=${customer_string}`);
      const customerFiltersData = customerFilters.data;
      setCustomerFilter(customerFiltersData);

      const productFilters = await api.get(`/api/products/?fields=${item_string}`);
      const productFiltersData = productFilters.data;
      setProductFilter(productFiltersData);

      localStorage.setItem("locationFilters", JSON.stringify(locationFiltersData));
      localStorage.setItem("productFilters", JSON.stringify(productFiltersData));
      localStorage.setItem("customerFilters", JSON.stringify(customerFiltersData));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error.message);
      // alert("Error while fetching APIs for Filter");
    }
  };

  const checkAndFetchUiMapping = async () => {
    const lastFetchDateUiMapping = localStorage.getItem("lastFetchDateUiMapping");
    const today = new Date().toISOString().split('T')[0];

    if (lastFetchDateUiMapping !== today) {
      await getUiMapping();
      localStorage.setItem("lastFetchDateUiMapping", today);
    } else {
      const cachedUiMapping = localStorage.getItem("uiMapping");
      if (cachedUiMapping) {
        setUimappingData(JSON.parse(cachedUiMapping));
      }
    }
  };

  const checkAndFetchFilterItems = async () => {
    const lastFetchDateFilters = localStorage.getItem("lastFetchDateFilters");
    const today = new Date().toISOString().split('T')[0];

    if (lastFetchDateFilters !== today) {
      await getFilterItems();
      localStorage.setItem("lastFetchDateFilters", today);
    } else {
      const cachedLocationFilters = localStorage.getItem("locationFilters");
      const cachedCustomerFilters = localStorage.getItem("customerFilters");
      const cachedProductFilters = localStorage.getItem("productFilters");

      if (cachedLocationFilters && cachedCustomerFilters && cachedProductFilters) {
        setLocationFilter(JSON.parse(cachedLocationFilters));
        setCustomerFilter(JSON.parse(cachedCustomerFilters));
        setProductFilter(JSON.parse(cachedProductFilters));
      }
    }
  };



  useEffect(() => {
    checkAndFetchUiMapping();
  }, []);

  useEffect(() => {
    if (uiMappingData !== null) {
      checkAndFetchFilterItems();
    }
  }, [uiMappingData]);



  const [planningData, setPlanningData] = useState(null);
  const [tableLoading, settableLoading] = useState(false);
  const [additionalFieldsPerf, setAdditionalFieldsPerf] = useState([]);
  // const [fields, setFields] = useState({
  //   "base": "FQTY_ENGINE",
  //   "base_2": "SQTY",
  //   "base_3": "FQTY_BUDGET",
  //   "base_4": "FQTY_USER",
  //   "interval": "FMONTH",
  //   "item": null,
  //   "location": null,
  //   "category": null,
  // })
  const [perFields, setPerFields] = useState({
    "base": "FQTY_ENGINE",
    "base_2": "SQTY",
    "base_3": "FQTY_BUDGET",
    "base_4": "FQTY_USER",
    "interval": "FDATE",
    "item": null,
    "location": null,
    "category": null,
  })
  const [graphLoading, setGraphLoading] = useState(false);
  const [tdateVar, setTdateVar] = useState(null);
  const [intervalList, setIntervalList] = useState([]);
  const [checkboxString, setCheckBoxString] = useState("");
  const [columnsShownPerf, setColumnShownPerf] = useState([])
  const [callingAPI, setcallingAPI] = useState(false)



  const fetchParams = async () => {
    try {
      setLoading(true)
      const response = await api.get("/api/params/");
      const params = response.data;
      setParams(params);
      const item = params.find(param => param.PARAM_CODE === "UI1")?.USER_MODIFIED_VALUE
      const location = params.find(param => param.PARAM_CODE === "UI2")?.USER_MODIFIED_VALUE
      const category = params.find(param => param.PARAM_CODE === "UI3")?.USER_MODIFIED_VALUE

      const perItem = params.find(param => param.PARAM_CODE === "UI8")?.USER_MODIFIED_VALUE
      const perLocation = params.find(param => param.PARAM_CODE === "UI9")?.USER_MODIFIED_VALUE
      const perCategory = params.find(param => param.PARAM_CODE === "UI10")?.USER_MODIFIED_VALUE

      // Update perFields state
      setPerFields(prevPerFields => ({
        ...prevPerFields,
        item: perItem,
        location: perLocation,
        category: perCategory,
      }));
      // Update state
      setFields(prevFields => ({
        ...prevFields,
        item,
        location,
        category,
      }));
      // Store in localStorage
      localStorage.setItem('params', JSON.stringify(params));
      localStorage.setItem('fields', JSON.stringify({ item, location, category }));
      localStorage.setItem('perFields', JSON.stringify({ item: perItem, location: perLocation, category: perCategory }));
      setLoading(false);
    }
    catch (e) {
      setLoading(false)
      console.log(e);
    }
  }

  useEffect(() => {
    const storedFields = JSON.parse(localStorage.getItem('fields'));
    const storedPerFields = JSON.parse(localStorage.getItem('perFields'));
    const storedParams = JSON.parse(localStorage.getItem('params'));
    if (storedFields) {
      setParams(storedParams);
    }

    if (storedFields) {
      setFields(prevFields => ({
        ...prevFields,
        item: storedFields.item,
        location: storedFields.location,
        category: storedFields.category
      }));
    }

    if (storedPerFields) {
      setPerFields(prevPerFields => ({
        ...prevPerFields,
        item: storedPerFields.item,
        location: storedPerFields.location,
        category: storedPerFields.category
      }));
    }

    if (!storedFields || !storedPerFields || !storedParams) {
      fetchParams();
    }
  }, []);


  //performance code starts
  const [prefBarGrapLoading, setPrefBarGrapLoading] = useState(false)
  const [performanceLoading, setPerformanceLoading] = useState(false)
  const [performanceData, setPerformanceData] = useState(null)
  // const [performanceGraphData, setperformanceGraphData] = useState(null)
  const [performanceResult, setPerformanceResult] = useState(null)
  const [performanceGraphResult, setPerformanceGraphResult] = useState(null)
  const [errType, setErrType] = useState('ape')
  const [intervalType, setIntervalType] = useState("FDATE")
  // Get ORG_ID from localStorage and set initial agg_level based on its value
  const initialAggLevel = () => {
    const orgId = localStorage.getItem('ORG_ID');
    if (orgId === '10') {
      return ["ITEM", "LOCATION", "TDATE", "FDATE"];
    }
    return ["ITEM", "LOCATION","CUSTOMER", "TDATE", "FDATE"];
  };

  const [agg_level, setAgg_level] = useState(initialAggLevel);
  const [currentGraph, setCurrentGraph] = useState(null)
  const [lineGraphData, setLineGraphData] = useState(null)
  const [selectedATTs, setSelectedATTs] = useState([])
  const [dataWithBiasError, setDataWithBiasError] = useState([])
  //function for graph
  const calculateMetrics = (results, groupBy, metric) => {

    const groupedData = results && results.reduce((acc, row) => {
      const key = row[groupBy];
      if (!acc[key]) {
        acc[key] = { sqty: 0, fqty_final: 0, fqty_user: 0, tae: 0, tae_user: 0, ape: 0, ape_user: 0, count: 0 };
      }
      acc[key].sqty += row.sqty;
      acc[key].fqty_final += row.fqty_final;
      acc[key].fqty_user += row.fqty_user;
      acc[key].tae += row.tae;
      acc[key].tae_user += row.tae_user;
      acc[key].ape += row.ape;
      acc[key].ape_user += row.ape_user;
      acc[key].count += 1;
      return acc;
    }, {});

    const metricResults = groupedData && Object.keys(groupedData).reduce((acc, date) => {
      const { sqty, fqty_final, fqty_user, tae, tae_user, ape, ape_user, count } = groupedData[date];
      let metricValue, metricUserValue;
      if (metric === 'bias') {
        if (fqty_final === 0 && sqty === 0) {
          metricValue = 0;
        } else if (fqty_final !== 0 && sqty === 0) {
          metricValue = 100;
        } else if (fqty_final === 0 && sqty !== 0) {
          metricValue = -100;
        } else if (fqty_final !== 0 && sqty !== 0) {
          metricValue = ((fqty_final - sqty) * 100) / sqty;
        }

        if (fqty_user === 0 && sqty === 0) {
          metricUserValue = 0;
        } else if (fqty_user !== 0 && sqty === 0) {
          metricUserValue = 100;
        } else if (fqty_user === 0 && sqty !== 0) {
          metricUserValue = -100;
        } else if (fqty_user !== 0 && sqty !== 0) {
          metricUserValue = ((fqty_user - sqty) * 100) / sqty;
        }
      } 
      else if (metric === 'ape') {
        if (tae === 0 && sqty === 0) {
          metricValue = 0;
        } else if (tae !== 0 && sqty === 0) {
          metricValue = 100;
        } else if (tae === 0 && sqty !== 0) {
          metricValue = -100;
        } else if (tae !== 0 && sqty !== 0) {
          metricValue = (tae * 100) / sqty;
        }

        if (tae_user === 0 && sqty === 0) {
          metricUserValue = 0;
        } else if (tae_user !== 0 && sqty === 0) {
          metricUserValue = 100;
        } else if (tae_user === 0 && sqty !== 0) {
          metricUserValue = -100;
        } else if (tae_user !== 0 && sqty !== 0) {
          metricUserValue = (tae_user * 100) / sqty;
        }
      }
      else if (metric === 'mape') {
        metricValue = (ape / count) * 100;
        metricUserValue = (ape_user / count) * 100;
      }
      acc[date] = { [metric]: metricValue, [`${metric}_user`]: metricUserValue };
      return acc;
    }, {});

    return metricResults;
  };

  //function for table
  function calculateMetricsForTable(data) {
    const groupingKeys = Object.keys(data[0]).filter(key => key.startsWith('I') || key.startsWith('C') || key.startsWith('L'));

    const groupedData = {};

    data.forEach((item) => {
      const key = groupingKeys.map(key => item[key]).join('_');

      if (!groupedData[key]) {
        groupedData[key] = {
          ...groupingKeys.reduce((acc, key) => ({ ...acc, [key]: item[key] }), {}),
          sum_sqty: 0,
          sum_fqty_final: 0,
          sum_fqty_user: 0,
          sum_tae: 0,
          sum_tae_user: 0,
          sum_ape: 0,
          sum_ape_user: 0,
          count: 0,
        };
      }
      groupedData[key].sum_sqty += item.sqty;
      groupedData[key].sum_fqty_final += item.fqty_final;
      groupedData[key].sum_fqty_user += item.fqty_user;
      groupedData[key].sum_tae += item.tae;
      groupedData[key].sum_tae_user += item.tae_user;
      groupedData[key].sum_ape += item.ape;
      groupedData[key].sum_ape_user += item.ape_user;
      groupedData[key].count++;
    });

    const metricsData = Object.values(groupedData).map((group) => {
      var bias
      var bias_user
      // var mape
      // var mape_user
      var ape
      var ape_user

      // bias
      if (group.sum_fqty_final === 0 && group.sum_sqty === 0) {
        bias = 0
      }
      if (group.sum_fqty_final !== 0 && group.sum_sqty === 0) {
        bias = 100
      }
      if (group.sum_fqty_final === 0 && group.sum_sqty !== 0) {
        bias = -100
      }
      if (group.sum_fqty_final !== 0 && group.sum_sqty !== 0) {
        bias = ((group.sum_fqty_final - group.sum_sqty) / group.sum_sqty) * 100;
      }

      // bias_user
      if (group.sum_fqty_user === 0 && group.sum_sqty === 0) {
        bias_user = 0
      }
      if (group.sum_fqty_user !== 0 && group.sum_sqty === 0) {
        bias_user = 100
      }
      if (group.sum_fqty_user === 0 && group.sum_sqty !== 0) {
        bias_user = -100
      }
      if (group.sum_fqty_user !== 0 && group.sum_sqty !== 0) {
        bias_user = ((group.sum_fqty_user - group.sum_sqty) / group.sum_sqty) * 100;
      }

      // tae
      if (group.sum_tae === 0 && group.sum_sqty === 0) {
        ape = 0
      }
      if (group.sum_tae !== 0 && group.sum_sqty === 0) {
        ape = 100
      }
      if (group.sum_tae === 0 && group.sum_sqty !== 0) {
        ape = -100
      }
      if (group.sum_tae !== 0 && group.sum_sqty !== 0) {
        ape = (group.sum_tae / group.sum_sqty) * 100;
      }


      // tar_user
      if (group.sum_tae_user === 0 && group.sum_sqty === 0) {
        ape_user = 0
      }
      if (group.sum_tae_user !== 0 && group.sum_sqty === 0) {
        ape_user = 100
      }
      if (group.sum_tae_user === 0 && group.sum_sqty !== 0) {
        ape_user = -100
      }
      if (group.sum_tae_user !== 0 && group.sum_sqty !== 0) {
        ape_user = (group.sum_tae_user / group.sum_sqty) * 100;
      }




      // const bias = ((group.sum_fqty_final - group.sum_sqty) / group.sum_sqty) * 100;
      // const bias_user = ((group.sum_fqty_user - group.sum_sqty) / group.sum_sqty) * 100;
      // const ape = (group.sum_tae / group.sum_sqty) * 100;
      // const ape_user = (group.sum_tae_user / group.sum_sqty) * 100;
      const mape = (group.sum_ape / group.count) * 100;
      const mape_user = (group.sum_ape_user / group.count) * 100;

      const result = {};
      groupingKeys.forEach(key => {
        result[key] = group[key];
      });
      result.bias = bias.toFixed(2);
      result.bias_user = bias_user.toFixed(2);
      result.mape = mape.toFixed(2);
      result.mape_user = mape_user.toFixed(2);
      result.ape = ape.toFixed(2);
      result.ape_user = ape_user.toFixed(2);

      return result;
    });

    return metricsData;
  }

  function calculateErrors(data) {
    return data && data.map((item) => {
      let bias_error, bias_user_error;

      // Calculate bias_error with conditions
      if (item.fqty_final === 0 && item.sqty === 0) {
        bias_error = 0;
      } else if (item.fqty_final !== 0 && item.sqty === 0) {
        bias_error = 100;
      } else if (item.fqty_final === 0 && item.sqty !== 0) {
        bias_error = -100;
      } else if (item.fqty_final !== 0 && item.sqty !== 0) {
        bias_error = ((item.fqty_final - item.sqty) * 100 / item.sqty) - (((item.fqty_final - item.sqty) * 100 / item.sqty) % 10);
      }
      
      // Calculate bias_user_error with conditions
      if (item.fqty_user === 0 && item.sqty === 0) {
        bias_user_error = 0;
      } else if (item.fqty_user !== 0 && item.sqty === 0) {
        bias_user_error = 100;
      } else if (item.fqty_user === 0 && item.sqty !== 0) {
        bias_user_error = -100;
      } else if (item.fqty_user !== 0 && item.sqty !== 0) {
        bias_user_error = ((item.fqty_user - item.sqty) * 100 / item.sqty) - (((item.fqty_user - item.sqty) * 100 / item.sqty) % 10);
      }
      return {
        ...item,
        bias_error: bias_error,
        bias_user_error: bias_user_error
      }
    })
  }
  function filterPerformanceResult() {
    return dataWithBiasError.filter(entry => {
      return selectedATTs.some(att => {
        return Object.keys(att).every(key => {
          return entry[key] === att[key];
        });
      });
    });
  }
  function calculateBiasErrorSums(data, apiCall = false) {


    if (apiCall === true) {
      var filteredData = data
    }
    else {
      var filteredData = selectedATTs.length === 0 ? data : filterPerformanceResult()
    }

    // Initialize variables for min and max
    let minBiasError = Infinity;
    let maxBiasError = -Infinity;
    let minBiasUserError = Infinity;
    let maxBiasUserError = -Infinity;

    // Initialize objects to store sums
    const biasErrorSum = {};
    const biasUserErrorSum = {};
    // Find min and max values and accumulate sums
    filteredData && filteredData.forEach(item => {
      // Update min and max values
      minBiasError = Math.min(minBiasError, item.bias_error);
      maxBiasError = Math.max(maxBiasError, item.bias_error);
      minBiasUserError = Math.min(minBiasUserError, item.bias_user_error);
      maxBiasUserError = Math.max(maxBiasUserError, item.bias_user_error);

      // Accumulate sqty for bias_error
      const biasErrorValue = item.bias_error;
      if (!biasErrorSum.hasOwnProperty(biasErrorValue)) {
        biasErrorSum[biasErrorValue] = 0;
      }
      biasErrorSum[biasErrorValue] += item.sqty;
      // console.log("---biasErrorValue", biasErrorValue);

      // Accumulate sqty for bias_user_error
      const biasUserErrorValue = item.bias_user_error;
      if (!biasUserErrorSum.hasOwnProperty(biasUserErrorValue)) {
        biasUserErrorSum[biasUserErrorValue] = 0;
      }
      biasUserErrorSum[biasUserErrorValue] += item.sqty;
      // console.log("---biasUserErrorValue", biasUserErrorValue);

    });
    // Calculate finalMin and finalMax based on the min and max values found
    const finalMin = Math.min(minBiasError, minBiasUserError);
    const finalMax = Math.max(maxBiasError, maxBiasUserError);

    // Generate the output in the specified format
    const output = {
      bias_error: {},
      bias_user_error: {}
    };

    // Populate bias_error output
    for (let i = finalMin; i <= finalMax; i += 10) {
      output.bias_error[i] = biasErrorSum[i] || 0;
    }

    // Populate bias_user_error output
    for (let i = finalMin; i <= finalMax; i += 10) {
      output.bias_user_error[i] = biasUserErrorSum[i] || 0;
    }

    // Sort keys in ascending order
    output.bias_error = sortObjectKeys(output.bias_error);
    output.bias_user_error = sortObjectKeys(output.bias_user_error);
    return output;
  }

  // Helper function to sort object keys numerically
  function sortObjectKeys(obj) {
    // Get the keys and sort them numerically
    const sortedKeys = Object.keys(obj).sort((a, b) => parseInt(a) - parseInt(b));

    // Create a new object with sorted keys
    const sortedObj = {};
    sortedKeys.forEach(key => {

      sortedObj[key] = obj[key];

    });

    return sortedObj;
  }



  const ensureRangeForBoth = (biasErrorObj, biasUserErrorObj, min = -100, max = 100) => {
    const processObject = (obj) => {
      const result = {};
      for (let i = min; i <= max; i += 10) {
        result[i] = obj.hasOwnProperty(i) ? obj[i] : 0;
      }
      return result;
    };

    const bias_error = processObject(biasErrorObj);
    const bias_user_error = processObject(biasUserErrorObj);

    return { bias_error, bias_user_error };
  };
  const getAllPerformanceData = async () => {
    try {
      setPerformanceLoading(true)
      setSelectedATTs([])
      setErrType("ape")
      setIntervalType("FDATE")
      var additionalFieldString = additionalFieldsPerf.join(",")

      let response;
      // const org_id = localStorage.getItem("ORG_ID")
      // console.log("--------org", org_id === 10)
      let aggLevelString
      // if(org_id === '10'){
      //   const agg_lvl_without_Cust = agg_level.filter((item) => item !== "CUSTOMER")
      //   console.log("------agg_lvl_without_Cust-",agg_lvl_without_Cust );
      //   aggLevelString = agg_lvl_without_Cust.join(',');

      // }
      // else{
        aggLevelString = agg_level.join(',');
      // }

      if (additionalFieldsPerf.length === 0) {
        response = await api.get("/api/kpi/?fields=" + perFields["item"] + "," + perFields["location"] + "," + perFields["category"] + ",TDATE,FDATE" + `&agglevel=${aggLevelString}` + filterString);
      }
      else {
        response = await api.get("/api/kpi/?fields=" + additionalFieldString + ",TDATE,FDATE" + `&agglevel=${aggLevelString}` + filterString);
      }
      const responseData = response.data;
      setPerfPage(responseData.count)

      //for bar braph
      // const displayGraph = calculateMetrics(responseData.results, intervalType, errType);
      // setCurrentGraph(displayGraph)


      //for table
      const displayTable = calculateMetricsForTable(responseData.results);

      setPerformanceData(displayTable)

      setPerformanceResult(responseData.results)

      //line graph
      const lineGraphResult = calculateErrors(responseData.results)

      setDataWithBiasError(lineGraphResult)

      // const output = calculateBiasErrorSums(lineGraphResult)
      // const result = ensureRangeForBoth(output.bias_error, output.bias_user_error);

      // 
      // setLineGraphData(result)

      setPerformanceLoading(false)
    }
    catch (error) {
      message.info(error)
    }
  }


  const getPerformanceGraph = async () => {
    try {
      setPrefBarGrapLoading(true)
      setSelectedATTs([])
      var additionalFieldString = additionalFieldsPerf.join(",")

      let response;

      const aggLevelString = agg_level.join(',');
      // if (additionalFields.length === 0) {
      response = await api.get("/api/kpi/?fields=" + `${intervalType}` + `&forGraph=FDATE&agglevel=${aggLevelString}` + filterString);
      // }
      // else {
      //   response = await api.get("/api/kpi/?fields=" + "TDATE,FDATE" + "," + additionalFieldString + `&agglevel=${aggLevelString}` + filterString);
      // }
      const responseData = response.data;

      setPerformanceGraphResult(responseData.results)
      //for bar braph
      const displayGraph = calculateMetrics(responseData.results, intervalType, errType);

      setCurrentGraph(displayGraph)

      //line graph
      const errors = calculateErrors(responseData.results)

      const output = calculateBiasErrorSums(errors, true)

      const result = ensureRangeForBoth(output.bias_error, output.bias_user_error);

      setLineGraphData(result)

      setPrefBarGrapLoading(false)
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (uiMappingData !== null && perFields.item !== null) {
      getPerformanceGraph();
    }
  },[intervalType])


  useEffect(() => {
    if (performanceGraphResult && selectedATTs.length === 0) {

      const displayGraph = calculateMetrics(performanceGraphResult, intervalType, errType);
      setCurrentGraph(displayGraph)
    }
  }, [errType])

  useEffect(() => {
    if (performanceGraphResult && selectedATTs.length === 0) {
      const displayGraph = calculateMetrics(performanceGraphResult, intervalType, errType);
      setCurrentGraph(displayGraph)

      const errors = calculateErrors(performanceGraphResult)

      const output = calculateBiasErrorSums(errors)

      const result = ensureRangeForBoth(output.bias_error, output.bias_user_error);
      
      setLineGraphData(result)
    }
    else if (dataWithBiasError) {

      const output = calculateBiasErrorSums(dataWithBiasError)
      const result = ensureRangeForBoth(output.bias_error, output.bias_user_error);

      setLineGraphData(result)
    }
  }, [selectedATTs])

  useEffect(() => {
    if (uiMappingData !== null && perFields.item !== null) {
      // getPerformanceData();
      getAllPerformanceData();
      getPerformanceGraph();
    }
  }, [perFields, additionalFieldsPerf, filterString, uiMappingData, agg_level])
  //performance code ends


  const { setplanningTableLoading } = useBase()

  const getData = async (page = 1) => {
    try {
      settableLoading(true);
      setcallingAPI(true)
      var additionalFieldString = additionalFields.join(",")


      let response;

      if (page <= 2) {
        setplanningTableLoading(true)
        if (additionalFields.length === 0) {
          response = await api.get("/api/forecastcube/?fields=" + fields["item"] + "," + fields["location"] + "," + fields["category"] + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "," + "OPEN_OQTY,QQTY" + filterString + "&page=1");
        }
        else {
          response = await api.get("/api/forecastcube/?fields=" + additionalFieldString + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "," + "OPEN_OQTY,QQTY" + filterString + "&page=1");
        }
        var responseData = response.data;
        setNext(responseData.next)
        setPlanningData(responseData.results);
        setplanningTableLoading(false)

        settableLoading(false);

        if (responseData.next !== null) {

          if (additionalFields.length === 0) {
            response = await api.get("/api/forecastcube/?fields=" + fields["item"] + "," + fields["location"] + "," + fields["category"] + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "," + "OPEN_OQTY,QQTY" + filterString + `&page=2`);
          }
          else {
            response = await api.get("/api/forecastcube/?fields=" + additionalFieldString + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "," + "OPEN_OQTY,QQTY" + filterString + `&page=2`);
          }
          responseData = response.data;
          setNext(responseData.next)
          setPlanningData((prev) => [...prev, ...responseData.results])
        }



      }


      if (page > 2 && next !== null) {
        message.info("Fetching more data")

        if (additionalFields.length === 0) {
          response = await api.get("/api/forecastcube/?fields=" + fields["item"] + "," + fields["location"] + "," + fields["category"] + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "," + "OPEN_OQTY,QQTY" + filterString + `&page=${page}`);
        }
        else {
          response = await api.get("/api/forecastcube/?fields=" + additionalFieldString + "," + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "," + "OPEN_OQTY,QQTY" + filterString + `&page=${page}`);
        }
        responseData = response.data;
        setNext(responseData.next)
        setPlanningData((prev) => [...prev, ...responseData.results])
      }
      if (next === null) {
        // message.success("All data is shown");
      }
      setcallingAPI(false)
    } catch (error) {
      message.warning("Something went wrong");
    }
  }



  const getTDate = async () => {
    try {
      settableLoading(true);
      const data = await api.get("/api/forecastcube/?fields=TDATE" + "&page=1")

      const tdate = data.data;
      var date = "";
      if (tdate) {
        date = tdate["results"][0]["TDATE"]
        setTdateVar(date)
        const dateObj = new Date(date)
        dateObj.setFullYear(dateObj.getFullYear() - 1);
        const yearBackDate = dateObj.toISOString().slice(0, 10);
        if (startDate === null) {
          setStartDate(yearBackDate)
        }
      }
      else {
        date = "EMPTY"
      }
      settableLoading(false);

    } catch (error) {
      // alert(error)
      console.log(error);
    }
  }


  const getIntervals = async () => {
    try {
      settableLoading(true);
      settableLoading(true);


      const response = await api.get("/api/forecastcube/?fields=" + fields["interval"] + "&page=1");
      const responseData = response.data;

      setIntervalList(responseData.results);


      settableLoading(false);
      settableLoading(false);

    } catch (error) {
      // alert(error.message);
      console.log(error);
    }
  }

  const getGraphData = async () => {
    try {
      setGraphLoading(true);
      settableLoading(true);
      const response = await api.get("/api/forecastcube/?fields=" + fields["interval"] + "," + fields["base_2"] + "," + fields["base"] + "," + fields["base_3"] + "," + fields["base_4"] + "&page=1" + filterString);
      const responseData = response.data;
      setGraphData(responseData);

      setGraphLoading(false);
      settableLoading(false);

    } catch (error) {
      // alert(error.message);
      console.log(error);
    }
  }


  // useEffect(() => {
  //   if (uiMappingData !== null && planningData === null) {

  //     setFields((prevState) => {
  //       return {
  //         "item": uiMappingData[0]["ITEM"],
  //         "location": uiMappingData[0]["LOCATION"],
  //         "category": uiMappingData[0]["CUSTOMER"],
  //         ...prevState
  //       }
  //     })
  //   }
  // }, [uiMappingData])
  // useEffect(() => {
  //   getGraphData();
  // }, [])

  useEffect(() => {
    var new_arr = []
    for (var x = 0; x < columnsShown.length; x++) {
      new_arr.push(columnsShown[x])
    }
    const sortedFields = new_arr.sort((a, b) => {
      const order = { 'I': 1, 'L': 2, 'C': 3 }; // Define the custom order
      const firstCharA = a.charAt(0); // Get the first character of a
      const firstCharB = b.charAt(0); // Get the first character of b

      return order[firstCharA] - order[firstCharB]; // Sort based on the custom order
    });
    setAdditionalFields(sortedFields)
  }, [columnsShown])

  useEffect(() => {
    var new_arr = []
    for (var x = 0; x < columnsShownPerf.length; x++) {
      new_arr.push(columnsShownPerf[x])
    }
    setAdditionalFieldsPerf(new_arr)
  }, [columnsShownPerf])

  useEffect(() => {
    if (uiMappingData !== null && fields.item !== null && canFetchPlan) {
      setNext(true)
      getData();
      getGraphData();
      getIntervals();
      getTDate();
    }
  }, [fields, additionalFields, filterString, uiMappingData, canFetchPlan])
  useEffect(() => {
    if (planningData !== null) {
      getData(page);
    }
  }, [page]);

  const [collapsed, setCollapsed] = useState(true);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <LoadingOverlay isLoading={loading}>
      <Layout
        style={{
          minHeight: "100vh",
          background: "#F9F9FC",
        }}
        className=" w-full"
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          width={79}
          className="fixed mainsidebar z-50"
          style={{
            background: "#F9F9FC",
          }}
          theme="dark"
        >
          <Menu
            mode="vertical"
            style={{
              position: "fixed",
              width: collapsed ? 79 : 200,
              height: "100%",

            }}
            className="sidbarMenu"

          >
            <Menu.Item
              key="1"

              style={{
                backgroundColor: "#F9F9FC",
                borderRadius: 0,
                width: "100%",
                height: "100%",
                margin: 0,
                padding: 0, // Remove padding to eliminate extra space
                textAlign: "center", // Center the image
              }}
            >
              <div className="flex justify-start items-center">

                <img
                  src={horizonLogo}
                  alt="Horizon Logo"
                  style={{
                    backgroundColor: "#F9F9FC",
                    padding: 0,
                    margin: 0,
                    marginTop: "6px",
                    marginLeft: collapsed ? 8 : 0,
                    width: "60px", // Ensure the image fills the container
                    height: "auto", // Maintain aspect ratio
                    display: "block", // Remove extra space around the image
                  }}
                />
                {
                  !collapsed && <p className="font-semibold text-bodyTextBlue">Horizon Solutions</p>
                }
              </div>

            </Menu.Item>
          </Menu>
          <Menu
            mode="vertical"
            selectedKeys={selectedMenu}
            className="secondmenu"
            style={{ width: collapsed ? 79 : 200 }}
          >
            {
              orgType !== 'trial' &&
              <>
                <Menu.Item
                  key="dashboard"
                  icon={
                    <div>
                      <i className={`fa-solid fa-house ${selectedMenu === 'dashboard' ? "text-blue-500" : "text-slate-500"}`} style={{ width: 10 }} />
                      <p className="pl-6">{collapsed ? "" : "Dashboard"}</p>
                    </div>
                  }
                  className="menuItemStylemainmiddle"
                  onClick={() => setSelectedMenu('dashboard')}
                />
                <Menu.Item
                  key="details"
                  icon={
                    <div>
                      <i className={`fa-solid fa-chart-simple ${selectedMenu === 'details' ? "text-blue-500" : "text-slate-500"}`} style={{ width: 10 }} />
                      <p className="pl-6">{collapsed ? "" : "Demand"}</p>
                    </div>
                  }
                  className="menuItemStylemainmiddle"
                  onClick={() => setSelectedMenu('details')}
                />
                <Menu.Item
                  key="inventoryReviewBase"
                  icon={
                    <div>
                      <i className={`fa-solid fa-earth-americas ${selectedMenu === 'inventoryReviewBase' ? "text-blue-500" : "text-slate-500"}`} style={{ width: 10 }} />
                      <p className="pl-6">{collapsed ? "" : "Inventory Review"}</p>
                    </div>
                  }
                  className="menuItemStylemainmiddle"
                  onClick={() => setSelectedMenu('inventoryReviewBase')}
                />
                <Menu.Item
                  key="snop"
                  icon={
                    <div>
                      <i className={`fa-solid fa-database ${selectedMenu === 'snop' ? "text-blue-500" : "text-slate-500"}`} style={{ width: 10 }} />
                      <p className="pl-6">{collapsed ? "" : "SNOP"}</p>
                    </div>
                  }
                  className="menuItemStylemainmiddle"
                  onClick={() => setSelectedMenu('snop')}
                />
                <Menu.Item
                  key="supplyViewBase"
                  icon={
                    <div>
                      <i className={`fa-solid fa-truck ${selectedMenu === 'supplyViewBase' ? "text-blue-500" : "text-slate-500"}`} style={{ width: 10 }} />
                      <p className="pl-6">{collapsed ? "" : "Supply Review"}</p>
                    </div>
                  }
                  className="menuItemStylemainmiddle"
                  onClick={() => setSelectedMenu('supplyViewBase')}
                />
                <Menu.Item
                  key="diagram"
                  icon={
                    <div>
                      <i className={`fa-solid fa-diagram-successor ${selectedMenu === 'diagram' ? "text-blue-500" : "text-slate-500"}`} style={{ width: 10 }} />
                      <p className="pl-6">{collapsed ? "" : "Process"}</p>
                    </div>
                  }
                  className="menuItemStylemainmiddle"
                  onClick={() => setSelectedMenu('diagram')}
                />
                <Menu.Item
                  key="que"
                  icon={
                    <div>
                      <i className={`fa-solid fa-file-circle-question ${selectedMenu === 'que' ? "text-blue-500" : "text-slate-500"}`} style={{ width: 10 }} />
                      <p className="pl-6">{collapsed ? "" : "Questionnaire"}</p>
                    </div>
                  }
                  className="menuItemStylemainmiddle"
                  onClick={() => setSelectedMenu('que')}
                />
              </>
            }
            <Menu.Item
              key="dataSection"
              icon={
                <div>
                  <i className={`fa-solid fa-upload ${selectedMenu === 'dataSection' ? "text-blue-500" : "text-slate-500"}`} style={{ width: 10 }} />
                  <p className="pl-6">{collapsed ? "" : "Upload Data"}</p>
                </div>
              }
              className="menuItemStylemainmiddle"
              onClick={() => setSelectedMenu('dataSection')}
            />
          </Menu>

          <Menu mode="vertical" className="lastmenu" style={{ width: collapsed ? 79 : 200 }}>
            <Menu.Item
              key="1"
              icon={<div> <i className={`text-slate-500 fa-solid fa-calendar-days `} style={{ width: 10 }} /><p className="pl-6">{collapsed ? "" : "Calendar"}</p> </div>}
              className="lastmenuitem"
            />
            <Menu.Item
              key="2"
              icon={<div> <i className="fa-solid fa-video text-slate-500 " style={{ width: 10 }} /><p className="pl-6">{collapsed ? "" : "Video"}</p> </div>}
              className="lastmenuitem"
            />
            <Menu.Item
              key="3"
              icon={<div> <i className="fa-solid fa-file-lines text-slate-500 " style={{ width: 10 }} /><p className="pl-6">{collapsed ? "" : "Files"}</p> </div>}
              className="lastmenuitem"
            />
            <Menu.Item
              key="4"
              icon={<div> <i className="fa-solid fa-gear text-slate-500" style={{ width: 10 }} /><p className="pl-6">{collapsed ? "" : "Settings"}</p> </div>}
              className="lastmenuitem"
            />
          </Menu>
        </Sider>
        {
          selectedMenu === 'dashboard' &&
          <DashboardContainer />
        }
        {
          selectedMenu === 'details' &&
          <Layout className="h-full " style={{ background: "#F9F9FC" }}>
            <header
              className="bg-[#f9f9fc] w-full h-full z-30"
            >
              {
                Object.keys(locationFilter).length > 0 && Object.keys(productFilter).length > 0 && Object.keys(customerFilter).length > 0
                  ?
                  <HeaderComponent selectedArea={selectedArea} uiMappingData={uiMappingData} filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilter} productFilters={productFilter} customerFilters={customerFilter} setSelectedArea={setSelectedArea} /> : null
              }

            </header>
            <Content
              className="content   w-full h-full overflow-scroll"
            >

              {
                selectedArea === "planning" && Object.keys(locationFilter).length > 0 && Object.keys(productFilter).length > 0 && Object.keys(customerFilter).length > 0
                  ?
                  <PlanningOverview
                    setNext={setNext}
                    callingAPI={callingAPI}
                    page={page}
                    setPage={setPage}
                    adjLoading={adjLoading}
                    setAdjLoading={setAdjLoading}
                    filterString={filterString}
                    getData={getData}
                    getGraphData={getGraphData}
                    columnsShown={columnsShown}
                    setColumnShown={setColumnShown}
                    setCheckBoxString={setCheckBoxString}
                    graphLoading={graphLoading}
                    tdateVar={tdateVar}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    intervalList={intervalList}
                    graphData={graphData}
                    fields={fields}
                    setFields={setFields}
                    planningData={planningData}
                    tableLoading={tableLoading}
                    additionalFields={additionalFields}
                    handleRefresh={handleRefresh}
                    uiMappingData={uiMappingData}
                    setFilterString={setFilterString}
                    locationFilters={locationFilter}
                    productFilters={productFilter}
                    customerFilters={customerFilter} /> : null
              }
              {
                selectedArea === "method" ?
                  <Methods locationFilters={locationFilter} productFilters={productFilter} customerFilters={customerFilter} /> : null
              }
              {
                selectedArea === "alert" ?
                  <Alerts locationFilters={locationFilter} productFilters={productFilter} customerFilters={customerFilter} /> : null
              }
              {
                selectedArea === "performance" ?
                  <Performance
                    additionalFields={additionalFieldsPerf}
                    columnsShown={columnsShownPerf}
                    setColumnShown={setColumnShownPerf}
                    selectedATTs={selectedATTs}
                    setSelectedATTs={setSelectedATTs}
                    calculateMetrics={calculateMetrics}
                    performanceResult={performanceResult}
                    setCurrentGraph={setCurrentGraph}
                    lineGraphData={lineGraphData}
                    tableLoading={tableLoading}
                    intervalType={intervalType}
                    setIntervalType={setIntervalType}
                    getAllPerformanceData={getAllPerformanceData}
                    prefBarGrapLoading={prefBarGrapLoading}
                    currentGraph={currentGraph}
                    // performanceGraphData={performanceGraphData}
                    agg_level={agg_level}
                    setAgg_level={setAgg_level}
                    errType={errType}
                    setErrType={setErrType}
                    uiMappingData={uiMappingData}
                    // getPerformanceData={getPerformanceData}
                    performanceData={performanceData}
                    tdateVar={tdateVar}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    intervalList={intervalList}
                    perFields={perFields}
                    setPerFields={setPerFields}
                    performanceLoading={performanceLoading}
                    locationFilters={locationFilter}
                    productFilters={productFilter}
                    customerFilters={customerFilter} />
                  : null
              }
              {
                selectedArea === "post" ?
                  <PostsContainer locationFilters={locationFilter} productFilters={productFilter} customerFilters={customerFilter} />
                  : null
              }
              {
                selectedArea === "portfolio" && Object.keys(locationFilter).length > 0 && Object.keys(productFilter).length > 0 && Object.keys(customerFilter).length > 0
                  ?
                  <PortfolioContainer uiMappingData={uiMappingData} filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilter} productFilters={productFilter} customerFilters={customerFilter} /> : null
              }
            </Content>
          </Layout>
        }
        {
          selectedMenu === "que" &&
          <QuesContainer />
        }
        {
          selectedMenu === "dataSection" && Object.keys(locationFilter).length >= 0 && Object.keys(productFilter).length >= 0 && Object.keys(customerFilter).length >= 0 &&
          <DataSection
            locationFilter={locationFilter}
            productFilter={productFilter}
            customerFilter={customerFilter}
            uiMappingData={uiMappingData}
            filterString={filterString}
            setFilterString={setFilterString}
          />
        }
        {
          selectedMenu === "diagram" &&
          <DiagramContainer />
        }
        {
          selectedMenu === "snop" &&
          <Snop
            filterString={filterString} setFilterString={setFilterString} locationFilters={locationFilter} productFilters={productFilter} customerFilters={customerFilter} uiMappingData={uiMappingData}
          />
        }
        {
          selectedMenu === 'supplyViewBase' && Object.keys(locationFilter).length > 0 && Object.keys(productFilter).length > 0 && Object.keys(customerFilter).length > 0 &&
          <SupplyViewBase
            setFilterString={setFilterString}
            setSelectedArea={setSelectedArea}
            setNext={setNext}
            callingAPI={callingAPI}
            page={page}
            setPage={setPage}
            adjLoading={adjLoading}
            setAdjLoading={setAdjLoading}
            filterString={filterString}
            getData={getData}
            getGraphData={getGraphData}
            columnsShown={columnsShown}
            setColumnShown={setColumnShown}
            setCheckBoxString={setCheckBoxString}
            graphLoading={graphLoading}
            tdateVar={tdateVar}
            startDate={startDate}
            setStartDate={setStartDate}
            intervalList={intervalList}
            graphData={graphData}
            fields={fields}
            setFields={setFields}
            planningData={planningData}
            tableLoading={tableLoading}
            additionalFields={additionalFields}
            handleRefresh={handleRefresh}
            uiMappingData={uiMappingData}
            locationFilters={locationFilter}
            productFilters={productFilter}
            customerFilters={customerFilter} />
        }
        {
          selectedMenu === 'inventoryReviewBase' && Object.keys(locationFilter).length > 0 && Object.keys(productFilter).length > 0 && Object.keys(customerFilter).length > 0 &&
          <InventoryReviewBase
            setFilterString={setFilterString}
            setSelectedArea={setSelectedArea}
            setNext={setNext}
            callingAPI={callingAPI}
            page={page}
            setPage={setPage}
            adjLoading={adjLoading}
            setAdjLoading={setAdjLoading}
            filterString={filterString}
            getData={getData}
            getGraphData={getGraphData}
            columnsShown={columnsShown}
            setColumnShown={setColumnShown}
            setCheckBoxString={setCheckBoxString}
            graphLoading={graphLoading}
            tdateVar={tdateVar}
            startDate={startDate}
            setStartDate={setStartDate}
            intervalList={intervalList}
            graphData={graphData}
            fields={fields}
            setFields={setFields}
            planningData={planningData}
            tableLoading={tableLoading}
            additionalFields={additionalFields}
            handleRefresh={handleRefresh}
            uiMappingData={uiMappingData}
            locationFilters={locationFilter}
            productFilters={productFilter}
            customerFilters={customerFilter} />
        }
      </Layout>
    </LoadingOverlay>
  );
};
export default BaseLayout;
