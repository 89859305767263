import React, { useEffect, useRef, useState } from 'react'
import { ExpandOutlined } from '@ant-design/icons';
import { FaSortUp } from "react-icons/fa6";
import { IoMdContract } from "react-icons/io";
import Overlay from '../../../checkboxOverlay';
import CombinedGraph from '../../../PlanningOverview/CombinedGraph';
import dayjs from 'dayjs';
import { Dropdown, message, DatePicker } from 'antd';
import EditOverlay from '../../../PlanningOverview/EditOverlay';
import 'ldrs/ring'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { useDownloadExcel } from 'react-export-table-to-excel';
import { FiDownload, FiUpload } from "react-icons/fi";
import { TailSpin } from 'react-loader-spinner';
import { BsThreeDots } from "react-icons/bs";
import { TbArrowNarrowUp, TbTablePlus } from "react-icons/tb";
import { MdOutlineExpandCircleDown } from "react-icons/md";
import DetailedProposal from '../../../PlanningOverview/DetailedProposal';
import { FaSortDown } from "react-icons/fa6";
import { useBase } from '../../../Layout/baseContext/BaseContext';
import { FilterOutlined, } from "@ant-design/icons/lib/icons";
import { CgRemoveR } from "react-icons/cg";
import { usePlan } from '../../../Layout/baseContext/planningContext/PlanningContext';
import FilterInATTPopUp from '../../../PlanningOverview/FilterInATTPopUp';
import { PiArrowsInSimple } from 'react-icons/pi';
import GraphEditPopUp from '../../../commonComponents/graphComponents/GraphEditPopUp';
import ExpandCheckboxList from '../../../PlanningOverview/ExpandCheckboxList';
import ProposalTable from '../../../PlanningOverview/ProposalTable';
import CommonExpandedRow from '../../../PlanningOverview/CommonExpandedRow';
import { useSupply } from '../../../Layout/baseContext/supplyContext/supplyContext';


const SupplyView = ({
    setNext,
    callingAPI,
    setPage,
    page,
    adjLoading,
    setAdjLoading,
    filterString,
    locationFilters,
    productFilters,
    customerFilters,
    getData,
    getGraphData,
    columnsShown,
    setColumnShown,
    setCheckBoxString,
    graphLoading,
    tdateVar,
    startDate,
    setStartDate,

    graphData,
    tableLoading,
    additionalFields,
    uiMappingData, handleRefresh }) => {


    const {
        inATTFilterPopUp, setinATTFilterPopUp,
        filterATT, setFilterATT,
        graphEditPopUp, setGraphEditPopUp,
        getProposals,
        proposalData,
        showAverage, showSum, setShowAverage, setShowSum
    } = usePlan()

    const { supplyData, supFields, setsupFields, supplyIntervals } = useSupply();
    const [checkBoxOverLay, setCheckBoxOverlay] = useState(false);
    const [overlayFlag, setOverlayFlag] = useState(false)

    const { supplyTransposedData, setSupplyTransposedData,
        sqty, setSqty,
        fqty, setFqty,
        dates, setDates,
        fqty_budget, setFqty_budget,
        fqty_planner, setfqty_planner,
        emptyAll,
        uncheckAll,
        flag, setflag,
        checkboxCount, setCheckboxCount,
        selectedItems, setSelectedItems,
        totalSqty, setTotalSqty,
        totalFqty, setTotalFqty,
        populateGraphDisplayData,
        graphDisplayData1, setGraphDisplayData1,
        graphDisplayData2, setGraphDisplayData2,
        graphDisplayData3, setGraphDisplayData3,
        graphDisplayData4, setGraphDisplayData4,
        graphDates, setGraphDates,
        endDate, setEndDate,
        checkIndexes, setCheckIndexes
    } = useBase();
    // const [supplyTransposedData, setSupplyTransposedData] = useState(new Map());

    const [unSortedTransposedData, setUnSortedTransposedData] = useState(new Map())
    const [detailedProposal, setDetailedProposal] = useState(false)
    const [sortedIndex, setSortedIndex] = useState(null);
    const [changes, setChanges] = useState([]);
    const tableRef = useRef(null);
    const tempTableRef = useRef(null);
    const tdRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [allSelected, setAllSelected] = useState(true)
    const [showZeroData, setShowZeroData] = useState(false)
    const [selectedItem, setSelectedItem] = useState(supFields.item)
    const [selectedLocation, setSelectedLocation] = useState(supFields.location)
    const [selectedCustomer, setSelectedCustomer] = useState(supFields.category)
    const divRef = useRef(null);
    const [expandRow, setExpandRow] = useState(null);
    const [listItems, setListItems] = useState({
        "Proposals": false,
        "Open Orders": false,
        "Confirmed Orders": false,
        "Due Orders": false,
        "Weather": false,
        "Price": false,
        "Promotion": false,
        "Events": false,
        "By Sales": false,
        "Consensus": false,
        "Inventory": false,
        "Point of Sales": false,
        "Sell out": false
    });
    const [Expandchecklist, setExpandchecklist] = useState({
        "BOH": false,
        "EOH": false,
        "POERQTY": false,
        "STERQTY": false,

    })
    const [sortString, setsortString] = useState("")

    function filterMap(transposeMap) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const filteredMap = new Map();

        for (const [key, value] of transposeMap.entries()) {
            let includeEntry = false;

            for (const [date, data] of Object.entries(value)) {
                const currentDate = new Date(date);

                if (currentDate >= start && currentDate <= end) {
                    if (data.FQTY !== 0 && data.FQTY !== null) {
                        includeEntry = true;
                        break;
                    }
                }
            }

            if (includeEntry) {
                filteredMap.set(key, value);
            }
        }
        return filteredMap;
    }


    const [intervalFiltered, setintervalFiltered] = useState([])

    const generateTransposeData = () => {
        if (supplyData !== null && supplyIntervals.length !== 0 && tdateVar !== null) {
            const transposeMap = new Map();

            const intervalListFiltered = supplyIntervals.filter(dateString => {
                const date = new Date(dateString[supFields.interval]);
                return date >= new Date(startDate) && date <= new Date(endDate)
            })
            setintervalFiltered(intervalListFiltered)
          


            supplyData && supplyData.forEach((data, index) => {
                var key = ""
                // if (additionalFields.length === 0) {
                key = `${data[supFields['item']]}=${data[supFields['location']]}`
                // }
                // else {
                //     // key = `${data[supFields['item']]}=${data[supFields['location']]}=${data[supFields['category']]}`

                //     for (var i = 0; i < additionalFields.length; i++) {
                //         if (i > 0) {
                //             key += "="
                //         }
                //         key += `${data[additionalFields[i]]}`
                //     }
                // }
                if (!transposeMap.has(key)) {
                    transposeMap.set(key, {});

                }
                const itemMap = transposeMap.get(key);

                intervalListFiltered.forEach((interval) => {
                    if (!itemMap[interval[supFields.interval]]) {
                        itemMap[interval[supFields.interval]] = {
                            "BOH": 0,
                            "EOH": 0,
                            "POERQTY": 0,
                            "STERQTY": 0,
                            "PRQTY": 0,
                        };
                    }
                });

                itemMap[data[supFields.interval]] = {
                    "BOH": data["BOH"],
                    "EOH": data["EOH"],
                    "POERQTY": data["POERQTY"],
                    "STERQTY": data["STERQTY"],
                    "PRQTY": data["PRQTY"],
                };

            });
            transposeMap.forEach((itemMap, key) => {
                Object.keys(itemMap).forEach(interval => {
                    if (!intervalListFiltered.some(filteredInterval => filteredInterval[supFields.interval] === interval)) {
                        delete itemMap[interval];
                    }
                });
            })


            // const sorted = sortMapBySumAvg(transposeMap, 'sum', 'sales');


            // const mapWithoutZeroes = filterMap(transposeMap);

            // if (showZeroData) {
            setSupplyTransposedData(transposeMap)
            setUnSortedTransposedData(transposeMap)
            // }
            // else {
            //     setSupplyTransposedData(mapWithoutZeroes)
            //     setUnSortedTransposedData(mapWithoutZeroes)
            // }

            return transposeMap;
        }
        return null;
    };


    useEffect(() => {
        generateTransposeData();
    }, [showZeroData])






    const addCheckIndex = (newIndex) => {
        setCheckIndexes((prev) => {
            let newTop = 2.6; // Default value for the first top

            if (prev.length > 0) {
                // Calculate new top based on the last entry
                const lastTop = prev[prev.length - 1].top;
                newTop = lastTop + 2;
            }

            // Create the new entry
            const newEntry = {
                index: newIndex,
                top: newTop,
            };

            // Create a new array with the new entry and sort it by `index`
            const updatedArray = [...prev, newEntry].sort((a, b) => a.index - b.index);
            // Adjust the `top` values after sorting
            return updatedArray.map((item, i) => ({
                ...item,
                top: 2.6 + i * 2, // Recalculate top values after sorting
            }));
        });
    };
    const removeCheckIndex = (removeIndex) => {
        setCheckIndexes((prev) => {
            // Filter out the item with the specified index
            const updatedArray = prev.filter(item => item.index !== removeIndex);

            // Recalculate the `top` values for the remaining items
            return updatedArray.map((item, i) => {
                return {
                    ...item,
                    top: 2.6 + i * 2, // Recalculate the top value based on position
                };
            });
        });
    };

    const isIndexPresent = (searchIndex) => {
        return checkIndexes.find(item => item.index === searchIndex) !== undefined;
    };

    const getTopForIndex = (rowIndex) => {
        const foundItem = checkIndexes.find(item => item.index === rowIndex);
        return foundItem ? foundItem.top : null;
    };

    const checkedGraphData = (correspondingObject, rowIndex) => {
        addCheckIndex(rowIndex)
        setCheckboxCount((prevCheckboxCount) => prevCheckboxCount + 1);

        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        if (flag === 0) {
            setflag(1);
            correspondingObject && Object.keys(correspondingObject).forEach((dateStr, index) => {
                const date = new Date(dateStr);

                if (date >= startDateObj && date <= endDateObj) {
                    dates.push(dateStr);

                    sqty.push(correspondingObject[dateStr].SQTY);
                    fqty.push(correspondingObject[dateStr].FQTY);
                    fqty_budget.push(correspondingObject[dateStr].FQTY_BUDGET === null ? null : correspondingObject[dateStr].FQTY_BUDGET)
                    fqty_planner.push(correspondingObject[dateStr].FQTY_USER === null ? null : correspondingObject[dateStr].FQTY_USER)
                }
            });
        }
        else {
            correspondingObject && Object.keys(correspondingObject).forEach((dateStr, index) => {
                const date = new Date(dateStr);
                if (date >= startDateObj && date <= endDateObj) {
                    sqty[index] = sqty[index] + correspondingObject[dateStr].SQTY;
                    fqty[index] = fqty[index] + correspondingObject[dateStr].FQTY;
                    if (correspondingObject[dateStr].FQTY_BUDGET === null) {
                        fqty_budget[index] = null
                    }
                    else {
                        fqty_budget[index] = fqty_budget[index] + correspondingObject[dateStr].FQTY_BUDGET
                    }
                    if (correspondingObject[dateStr].FQTY_USER === null) {
                        fqty_planner[index] = null
                    }
                    else {
                        fqty_planner[index] = fqty_planner[index] + correspondingObject[dateStr].FQTY_USER
                    }
                }
            });

        }



        // setGraphDisplayData1(sqty);
        // setGraphDisplayData2(fqty);
        // setGraphDisplayData3(fqty_budget)
        // setGraphDisplayData4(fqty_planner)
        // setGraphDates(dates);


    }



    var top = 2.6






    const { onDownload } = useDownloadExcel({
        currentTableRef: tempTableRef.current,
        filename: 'Users table',
        sheet: 'Users'
    })




    useEffect(() => {

        if (tdRef.current && sortOrder === null) {
            // tdRef.current.scrollIntoView({ behavior: 'smooth', inline: 'center' });
            scrollToOffsetCenter()
        }
    }, [supplyData, supplyTransposedData]);


    const [columnWidths, setColumnWidths] = useState({
        col1: 180,
        col2: 180,
        col3: 180,
    });
    const [dynamicColumnWidths, setdynamicColumnWidths] = useState({});
    const scrollToOffsetCenter = () => {
        if (tdRef.current && divRef.current) {
            const container = divRef.current;

            const tdElement = tdRef.current;
            let fromLeft = 0;
            // Calculate the current offset from the left and how much to scroll
            const targetPosition = tdElement.offsetLeft; // Position of tdRef from left of container
            if (additionalFields.length === 0) {
                fromLeft = columnWidths.col1 + columnWidths.col2 + columnWidths.col3
            }
            else {
                fromLeft = Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 0)
            }

            if ((showSum && !showAverage) || (!showSum && showAverage)) {
                fromLeft += 130
            }
            else if (showAverage && showSum) {
                fromLeft += 260
            }
            const scrollPosition = targetPosition - fromLeft - 90; // We want it 630px from the left  
            // Smoothly scroll to the calculated position
            container.scrollTo({
                left: scrollPosition,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        if (allSelected === true) {
            setSelectedItems([]);
        }
    }, [allSelected])
    useEffect(() => {
        uncheckAll();
    }, [supFields, adjLoading])

    useEffect(() => {
        if (graphData !== null && startDate !== null && endDate !== null && selectedItems.length === 0)
            // checkedGraphData();

            populateGraphDisplayData();
    }, [graphData, startDate, endDate])
    useEffect(() => {
        if (supplyIntervals.length !== 0) {

            setEndDate(supplyIntervals[supplyIntervals.length - 1][supFields["interval"]])

        }
    }, [supplyIntervals])
    useEffect(() => {
        if (supplyData !== null && supplyIntervals.length !== 0 && startDate !== null && endDate !== null) {
            setSortedIndex(-1);
            setSortOrder(null)
            generateTransposeData();
            // emptyAll();
            // setflag(0)
            // setCheckboxCount(-1)
            // setSelectedItems([]);

        }
    }, [supplyData, supplyIntervals, additionalFields, startDate, endDate])

    useEffect(() => {
        setPage(2);
    }, [supFields, filterString])

    function getSummedData(selectedItems, dataMap) {
        const summedData = {};

        selectedItems.forEach((item) => {
            const itemData = dataMap.get(item);

            if (itemData) {
                for (const [date, values] of Object.entries(itemData)) {
                    if (!summedData[date]) {
                        // Initialize if date entry doesn't exist yet
                        summedData[date] = { ...values }; // Clone the first values object
                    } else {
                        // Sum FQTY and SQTY, if they exist
                        summedData[date].FQTY =
                            (summedData[date].FQTY || 0) + (values.FQTY || 0);
                        summedData[date].SQTY =
                            (summedData[date].SQTY || 0) + (values.SQTY || 0);
                        summedData[date].FQTY_USER =
                            (summedData[date].FQTY_USER || 0) + (values.FQTY_USER || 0);
                        summedData[date].FQTY_BUDGET =
                            (summedData[date].FQTY_BUDGET || 0) + (values.FQTY_BUDGET || 0);
                    }
                }
            }
        });
        return summedData;
    }

    // Function to extract and set separate datasets
    function processAndSetGraphData(result, labels) {
        // Initialize datasets for SQTY, FQTY, FQTY_BUDGET, and FQTY_USER
        const SQTYData = [];
        const FQTYData = [];
        const FQTY_BUDGETData = [];
        const FQTY_USERData = [];

        labels.forEach((label) => {
            const entry = result[label];

            // Push values or default to 0/null
            SQTYData.push(entry?.SQTY || 0);
            FQTYData.push(entry?.FQTY || 0);
            FQTY_BUDGETData.push(entry?.FQTY_BUDGET || 0);
            FQTY_USERData.push(entry?.FQTY_USER || 0);
        });

        // Now set each dataset using the provided functions

        setGraphDisplayData1(SQTYData)
        setGraphDisplayData2(FQTYData)
        setGraphDisplayData3(FQTY_BUDGETData)
        setGraphDisplayData4(FQTY_USERData)
        setGraphDates(labels)

    }



    useEffect(() => {
        if (selectedItems.length !== 0) {
            var var_new_string = ""
            var var_item_arr = `&${supFields.item}=`;
            var var_location_arr = `&${supFields.location}=`;
            var var_customer_arr = `&${supFields.category}=`;
            selectedItems.map((item, index) => {
                const data = item.split("=")
                if (index !== selectedItems.length - 1) {
                    var_item_arr += data[0] + ","
                    var_location_arr += data[1] + ","
                    var_customer_arr += data[2] + ","
                }
                else {
                    var_item_arr += data[0]
                    var_location_arr += data[1]
                    var_customer_arr += data[2]
                }
            })
            var_new_string += var_item_arr + "&" + var_location_arr + "&" + var_customer_arr

            setCheckBoxString(encodeURI(var_new_string))
            setAllSelected(false)

            const result = getSummedData(selectedItems, supplyTransposedData);
            processAndSetGraphData(result, Object.keys(result));
        }
        else {
            setCheckBoxString("")
            populateGraphDisplayData()

        }
    }
        , [selectedItems, supplyTransposedData])

    const onClick = ({ key }) => {
        if (key === '1') {
            setShowZeroData(false);
            setSortedIndex(-1);
            message.info('Showing data without 0s');
        } else if (key === '2') {
            setShowZeroData(true);
            setSortedIndex(-1);
            message.info('Showing data with 0s');
        }
    };
    const items = [
        {
            label: 'Show Data without 0s',
            key: '1',
        },
        {
            label: 'Show Data with 0s',
            key: '2',
        }
    ];
    const [sortOrder, setSortOrder] = useState(null)
    const [keySorting, setKeySorting] = useState(false);

    function sortData(data, index) {
        uncheckAll();
        setKeySorting(true);
        setsortString("")

        let newSortOrder;

        // If the index changes, start with descending order
        if (index !== sortedIndex) {
            newSortOrder = false; // Start with descending order for the new column
        } else {
            // Determine the new sort order based on the current state
            if (sortOrder === null) {
                newSortOrder = false; // First double-click on the same column sorts in descending order
            } else if (sortOrder === false) {
                newSortOrder = true; // Second double-click sorts in ascending order
            } else {
                setSupplyTransposedData(unSortedTransposedData); // Third double-click restores original order
                setSortOrder(false);
                setSortedIndex(-1);
                setKeySorting(false);
                return;
            }
        }

        setSortOrder(newSortOrder);
        setSortedIndex(index);

        const sortedData = new Map(
            [...data.entries()].sort((a, b) => {
                const aValue = a[0].split('=')[index];
                const bValue = b[0].split('=')[index];

                if (!isNaN(aValue) && !isNaN(bValue)) {
                    return newSortOrder ? aValue - bValue : bValue - aValue;
                } else {
                    return newSortOrder ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }
            })
        );

        setSupplyTransposedData(sortedData);
    }



    function sortDataByDate(date, dataMap, index, asc) {
        uncheckAll();
        setKeySorting(false)
        setsortString("")
        if (sortOrder === asc && index === sortedIndex && !keySorting) {
            setSupplyTransposedData(unSortedTransposedData)
            setSortOrder(false);
            setSortedIndex(-1)
        }
        else {
            setSortOrder(asc)
            setSortedIndex(index);
            const dataArray = Array.from(dataMap.entries());

            dataArray.sort((a, b) => {
                const aData = a[1][date];
                const bData = b[1][date];

                let comparison = 0;
                if (aData.FQTY_USER !== null && bData.FQTY_USER !== null) {
                    comparison = aData.FQTY_USER - bData.FQTY_USER;
                } else if (aData.FQTY_USER !== null) {
                    comparison = -1;
                } else if (bData.FQTY_USER !== null) {
                    comparison = 1;
                } else {
                    comparison = aData.SQTY - bData.SQTY;
                }

                return asc ? comparison : -comparison;
            });

            const sortedMap = new Map(dataArray);
            if (showZeroData) {
                setSupplyTransposedData(sortedMap)
            }
            else {
                const sortedWithoutZeroes = filterMap(sortedMap)
                setSupplyTransposedData(sortedWithoutZeroes)
            }


        }
    }





    const [cwidth, setcwidth] = useState(-1)


    const handleScroll = () => {
        if (divRef.current && !callingAPI) {
            const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = divRef.current;
            setcwidth(scrollLeft)
            if ((scrollTop + clientHeight >= scrollHeight - 1) && scrollLeft === cwidth) {
                setPage((prevPage) => prevPage + 1);

            }
        }
    };


    useEffect(() => {
        const divElement = divRef.current;
        if (divElement) {
            divElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener('scroll', handleScroll);
            }
        };
    });

    var midIndex = 0;







    const col1Ref = useRef(null);
    const col2Ref = useRef(null);
    const col3Ref = useRef(null);

    const handleMouseDown = (e, colKey, colRef) => {
        const startX = e.clientX;

        const startWidth = colRef.current.offsetWidth;

        const handleMouseMove = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            if (newWidth > 50) {

                setColumnWidths((prevWidths) => {
                    // Spread the current state to a new object

                    const updatedWidths = { ...prevWidths, [colKey]: newWidth };
                    // Return the new state object
                    return updatedWidths;
                });
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };



    const columnRefs = useRef({});

    useEffect(() => {
        // Update dynamic column widths when additionalFields is updated
        const newDynamicColumnWidths = additionalFields.reduce((widths, col, index) => {
            widths[`col${index}`] = 180; // Default width of 180
            return widths;
        }, {});

        setdynamicColumnWidths(newDynamicColumnWidths);

        // Update refs when additionalFields is updated
        columnRefs.current = additionalFields.reduce((refs, col, index) => {
            refs[`col${index}`] = React.createRef();
            return refs;
        }, {});
    }, [additionalFields]);



    const handleMouseDownDynamic = (e, colKey) => {
        const colRef = columnRefs.current[colKey];
        const startX = e.clientX;
        const startWidth = colRef.current.offsetWidth;

        const handleMouseMove = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            if (newWidth > 50) { // Ensure minimum width of 50px
                setdynamicColumnWidths((prevWidths) => {
                    const updatedWidths = { ...prevWidths, [colKey]: newWidth }
                    return updatedWidths
                })
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };
    const checkboxRefs = useRef([]); // Array to store refs for each checkbox
    // Helper function to handle keydown event
    const handleKeyDown = (e, index) => {
        const currentItem = [...supplyTransposedData.keys()][index];
        if (e.key === 'ArrowDown') {
            if (index < supplyTransposedData.size - 1) {
                // Uncheck the current checkbox and check the next one
                // uncheckGraphData(correspondingObject, index);
                setSelectedItems(prevState => prevState.filter(item => item !== currentItem));

                const nextItem = [...supplyTransposedData.keys()][index + 1];
                // checkedGraphData(nextCorrespondingObject, index + 1);
                setSelectedItems(prevState => [...prevState, nextItem]);

                // Move focus to the next checkbox
                checkboxRefs.current[index + 1].focus();
            }
        } else if (e.key === 'ArrowUp') {
            if (index > 0) {
                // Uncheck the current checkbox and check the previous one
                // uncheckGraphData(correspondingObject, index);
                setSelectedItems(prevState => prevState.filter(item => item !== currentItem));

                const prevItem = [...supplyTransposedData.keys()][index - 1];
                // checkedGraphData(prevCorrespondingObject, index - 1);
                setSelectedItems(prevState => [...prevState, prevItem]);

                // Move focus to the previous checkbox
                checkboxRefs.current[index - 1].focus();
            }
        }
    };

    const barData1 = {
        labels: graphDates !== null ? graphDates.map((item) => item) : [],
        datasets: [
            {
                label: 'SQTY',
                data: graphDisplayData1 !== null ? graphDisplayData1 : [],
                backgroundColor: "#ffdb5c",
                borderWidth: 1,
            },
        ],
    };

    const barData2 = {
        labels: graphDates !== null ? graphDates.map((item) => item) : [],
        datasets: [
            {
                label: supFields["base"],
                data: graphDisplayData2 !== null ? graphDisplayData2 : [],
                backgroundColor: ["red", "blue", "green", "blue", "red", "blue"],
            },
        ],
    };
    const lineData1 = {
        labels: graphDates !== null ? graphDates.map((item) => item) : [],
        datasets: [
            {
                label: supFields.base_3,
                data: graphDisplayData3 !== null ? graphDisplayData3 : [],
                backgroundColor: "blue"
            },
        ],
    };
    const lineData2 = {
        labels: graphDates !== null ? graphDates.map((item) => item) : [],
        datasets: [
            {
                label: supFields["base_4"],
                data: graphDisplayData4 !== null ? graphDisplayData4 : [],
                backgroundColor: "red"
            },
        ],
    };


    const isDateInRange = (date, start, end) => {
        return new Date(date) >= new Date(start) && new Date(date) <= new Date(end);
    };

    // Step 2: Function to calculate sum and average of SQTY between dates
    const calculateSumAndAvg = (data, type, isSum) => {
        let sum = 0;
        let count = 0;

        if (type === 'sales') {
            Object.keys(data).forEach((date) => {
                if (isDateInRange(date, startDate, tdateVar)) {
                    const sqty = data[date].SQTY;
                    if (sqty !== null) {
                        sum += sqty;
                        count += 1;
                    }
                }
            });
        }

        if (type === 'forecast') {
            Object.keys(data).forEach((date) => {
                if (isDateInRange(date, tdateVar, endDate)) {
                    const fqty = data[date].FQTY;
                    if (fqty !== null) {
                        sum += fqty;
                        count += 1;
                    }
                }
            });
        }


        const avg = count > 0 ? sum / count : 0;

        if (isSum) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
            }).format(sum);
        }
        else {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 1
            }).format(avg);
        }
    };

    // Helper function to check if a change matches the current details and interval
    const doesChangeMatch = (change, details, interval, additionalFields) => {
        if (additionalFields.length > 0) {
            // Check all dynamic supFields from additionalFields
            return additionalFields.every((field, idx) => change[field] === details[idx]) && change["interval"] === interval;
        } else {
            // Check for hardcoded supFields when additionalFields is empty
            return change[selectedItem] === details[0] &&
                change[selectedLocation] === details[1] &&
                change[selectedCustomer] === details[2] &&
                change["interval"] === interval;
        }
    };

    // Helper function to create a new entry for the changes array
    const createNewEntry = (item, details, interval, additionalFields) => {
        let newEntry = {
            FQTY_USER: parseInt(item.FQTY),
            interval: interval,
        };

        if (additionalFields.length > 0) {
            additionalFields.forEach((field, idx) => {
                newEntry[field] = details[idx];
            });
        } else {
            newEntry[selectedItem] = details[0];
            newEntry[selectedLocation] = details[1];
            newEntry[selectedCustomer] = details[2];
        }

        return newEntry;
    };


    const [orderOfSortForSalesForecast, setorderOfSortForSalesForecast] = useState("")
    function sortMapBySumAvg(mapData, sortType, sortingOf) {
        // Helper function to calculate sum or average

        uncheckAll();
        setKeySorting(false)
        setSortOrder(null);
        setSortedIndex(-1)
        var sortOrderOfNumbers = "";
        const calculateValue = (data, field) => {
            let total = 0;
            let count = 0;

            setsortString(`${sortType}-${sortingOf}`)
            if (sortString === `${sortType}-${sortingOf}` && (orderOfSortForSalesForecast === "ascending" || orderOfSortForSalesForecast === "")) {
                sortOrderOfNumbers = "descending";
                setorderOfSortForSalesForecast("descending")

            }
            else if (sortString === `${sortType}-${sortingOf}` && (orderOfSortForSalesForecast === "descending")) {
                sortOrderOfNumbers = "ascending";
                setorderOfSortForSalesForecast("ascending")

            }
            else if (sortString !== `${sortType}-${sortingOf}`) {
                sortOrderOfNumbers = "descending"
                setorderOfSortForSalesForecast("descending")
            }
            for (const date in data) {
                if (data[date][field] !== null) {
                    total += data[date][field];
                    count++;
                }
            }

            if (sortType === 'average' && count > 0) {
                return total / count;
            }

            return total;
        };

        // Determine which field to use for sorting (SQTY for sales or FQTY_USER for forecast)
        const field = sortingOf === 'sales' ? 'SQTY' : 'FQTY_USER';

        // Convert map into an array for sorting
        const sortedArray = Array.from(mapData).sort((a, b) => {
            const valueA = calculateValue(a[1], field);
            const valueB = calculateValue(b[1], field);

            if (sortOrderOfNumbers === 'ascending') {
                return valueA - valueB;
            } else {
                return valueB - valueA;
            }
        });


        setSupplyTransposedData(new Map(sortedArray))
        return new Map(sortedArray);
    }

    return (
        <div className='flex flex-col space-y-2 w-full  h-full relative overflow-hidden'>

            {
                graphLoading && tableLoading ?
                    (<div className='h-full flex justify-center items-center'><TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#2B3674"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    /></div>)
                    :
                    <>
                        <div className='flex flex-row w-full justify-between'>
                            <div className="flex flex-row items-center space-x-2 pl-4 text-base text-[#646666]">

                                <p >Forecast version of <strong>{new Date(tdateVar).toLocaleDateString('en-US', { month: 'short' })}-{new Date(tdateVar).toLocaleDateString('en-US', { year: '2-digit' })}</strong> showing for</p>
                                <DatePicker className='px-2 py-1 text-xs font-medium bg-white rounded-full shadow-sm border border-gray-200 text-blue-600'
                                    value={dayjs(startDate)}
                                    onChange={(date, dateString) => {
                                        setStartDate(dateString)
                                    }}
                                />
                                <p>to</p>
                                <DatePicker className='px-2 py-1 text-xs font-medium bg-white rounded-full shadow-sm border border-gray-200 text-blue-600'
                                    value={dayjs(endDate)}
                                    onChange={(date, dateString) => {
                                        setEndDate(dateString)
                                    }}
                                />
                                <p>by</p>
                                <select className='px-3 py-1.5 text-xs bg-white rounded-full shadow-sm border border-gray-200 text-blue-600'
                                    value={supFields.interval}
                                    onChange={(e) => {
                                        setsupFields((prevState) => {
                                            return {
                                                ...prevState,
                                                "interval": e.target.value
                                            }
                                        })
                                    }}
                                >
                                    <option value={"FMONTH"}>Monthly</option>
                                    <option value={"FWEEK"}>Weekly</option>
                                    <option value={"FYEAR"}>Yearly</option>
                                </select>



                            </div>
                            <div className='flex flex-row items-center space-x-4'>

                                {changes.length !== 0 && !adjLoading ? <button className={`px-3 py-1 ${changes.length === 0 ? 'bg-gray-300' : 'bg-[#ed7d31]'} rounded-md text-sm text-white shadow-md`} disabled={changes.length === 0 ? true : false} onClick={() => {
                                    setChanges(prevArray => prevArray.slice(0, -1));
                                }}>Undo Changes</button> : null}
                                {!adjLoading &&
                                    <button className={`px-3 py-1 ${changes.length === 0 ? 'hidden' : 'bg-blue-500'} rounded-md text-sm text-white shadow-md`} disabled={changes.length === 0 ? true : false} onClick={() => setOverlayFlag(!overlayFlag)}>Save Changes</button>
                                }
                                <div className='flex items-center gap-2'>
                                    {adjLoading &&
                                        <div className="flex flex-row  items-center  p-2 rounded-full bg-white text-lg font-bold" >
                                            <div className='h-full flex justify-center items-center'>
                                                <TailSpin
                                                    visible={true}
                                                    height="15"
                                                    width="15"
                                                    color="#2563eb"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                                <span className='text-xs font-normal ml-2 text-blue-600'>Loading new Data</span>
                                            </div>
                                        </div>
                                    }

                                    <div onClick={onDownload} className="flex flex-row  items-center border border-slate-300 hover:bg-slate-100 p-2 rounded-full cursor-pointer text-slate-400 font-bold text-lg" data-tooltip-id="my-tooltip" data-tooltip-content="Download">
                                        <FiDownload />
                                    </div>
                                    <div className="flex flex-row  items-center  p-2 border border-slate-300  hover:bg-slate-100 rounded-full cursor-pointer text-slate-400 text-lg font-bold" data-tooltip-id="my-tooltip" data-tooltip-content="Upload">
                                        <FiUpload />
                                    </div>
                                    <div className="flex flex-row  items-center  p-2 border border-slate-300  hover:bg-slate-100 rounded-full cursor-pointer text-slate-400 text-lg font-bold">
                                        <Dropdown
                                            menu={{
                                                items,
                                                onClick,
                                            }}
                                        >
                                            <a onClick={(e) => e.preventDefault()}>

                                                <BsThreeDots />
                                            </a>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {
                            !isExpanded ?
                                <div className='flex flex-row  items-center p-6 bg-white rounded-lg w-full shadow-sm'>
                                    {
                                        <CombinedGraph lineData1={lineData1} lineData2={lineData2} barData1={barData1} barData2={barData2} duration={supFields["interval"]} />
                                    }
                                </div> : null
                        }


                        <div ref={divRef} className={`w-full ${isExpanded ? 'max-h-full' : 'h-full'} overflow-scroll  border relative scroll shadow-col  shadow-class`}>
                            {(
                                <>
                                    <table ref={tempTableRef} className=' hidden w-full whitespace-nowrap text-xs table-auto shadow-class border-collapse  transition-all ease-in-out duration-500 '>
                                        <thead className='w-full sticky top-0 border-b shadow-sm shadow-class bg-white z-50 ' >
                                            <tr className='font-bold  bg-slate-100'>
                                                {
                                                    additionalFields.length === 0 &&
                                                    <>
                                                        <td className='sticky bg-slate-100 shadow-col  left-[64px] z-40  rounded-tl-sm text-left  text-xs py-1 border-1 border-gray-200 min-w-[200px]'>
                                                            {uiMappingData[0][supFields["item"]]}
                                                        </td>
                                                        <td className='sticky min-w-[100px] shadow-col bg-slate-100  left-[304px] z-40 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200 '>
                                                            {uiMappingData[0][supFields["location"]]}

                                                        </td>
                                                        <td className='sticky min-w-[130px] shadow-col  bg-slate-100 left-[444px] z-40 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200'>
                                                            {uiMappingData[0][supFields["category"]]}

                                                        </td>
                                                    </>
                                                }

                                                {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                                    const space = (90 + ((index) * 180))
                                                    const leftValue = `${space}px`
                                                    return (
                                                        <td
                                                            style={{ left: leftValue }}
                                                            className={`sticky w-[180px] text-center shadow-col  px-3 text-xs py-1 border-1 border-gray-200 bg-white z-40`} key={index}>{uiMappingData[0][item]}</td>
                                                    )
                                                })}
                                                {supplyTransposedData.size !== 0 && Object.keys(supplyTransposedData.get([...supplyTransposedData.keys()][0])).map((item, index) => {
                                                    if (item === tdateVar && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                        midIndex = index
                                                        return (<React.Fragment key={index}>
                                                            <td colSpan={2} className='rounded-tl-sm shadow-col  text-center px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                                                        </React.Fragment>)
                                                    }
                                                    else {
                                                        if (midIndex !== 0 && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate) && index === midIndex + 4) {
                                                            return (<React.Fragment key={index}>
                                                                <td ref={tdRef} className='rounded-tl-sm shadow-col text-center px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                                                            </React.Fragment>)
                                                        }
                                                        else if (new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                            return (<React.Fragment key={index}>
                                                                <td className='rounded-tl-sm text-center shadow-col px-3 text-xs py-1 border-1 border-gray-200'>{item}</td>
                                                            </React.Fragment>)
                                                        }
                                                    }
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {supplyTransposedData.size !== 0 && [...supplyTransposedData.keys()].map((data, rowIndex) => {
                                                const details = data.split("=");
                                                return (
                                                    <>

                                                        <tr className='font-semibold transition-all ease-in-out duration-500' key={rowIndex}>

                                                            {/* {
                                                                additionalFields.length === 0 &&
                                                                <> */}
                                                            <td className='sticky bg-white shadow-col px-4  left-[64px]  min-w-[240px] text-left  py-2 text-xs border border-gray-200'>{details[0] !== null ? details[0] : "Not Available"}</td>
                                                            <td className='sticky bg-white shadow-col px-4 left-[304px] min-w-[130px] text-left  py-2 text-xs border border-gray-200'>{details[1] !== null ? details[1] : "Not Available"}</td>
                                                            {/* </>
                                                            } */}
                                                            {/* {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                                                const space = (90 + ((index) * 180))
                                                                const leftValue = `${space}px`
                                                                return (
                                                                    <td
                                                                        style={{ left: leftValue }}
                                                                        className={`sticky text-left px-3 py-2 w-[180px]  text-xs bg-white border border-gray-200`} key={index}>{details[index] !== null ? details[index] : "Not Available"}</td>
                                                                )
                                                            })} */}



                                                            {supplyTransposedData.size !== 0 && Object.values(supplyTransposedData.get(data)).map((item, index) => {
                                                                if (item["isSplit"] === true) {
                                                                    return (
                                                                        <>
                                                                            <td>
                                                                                <p className='p-2 h-full w-full'>{(item["SQTY"] !== null ? item["SQTY"] : 0)}</p>
                                                                            </td>
                                                                            <td onDoubleClick={() => {

                                                                                if (changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])) {
                                                                                    setChanges(prevState => prevState.filter(change => !(change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])));
                                                                                }
                                                                                else {
                                                                                    setChanges((prevState) => [...prevState, {
                                                                                        FQTY_USER: item.FQTY,
                                                                                        interval: intervalFiltered[index],
                                                                                        [selectedItem]: details[0],
                                                                                        [selectedLocation]: details[1],
                                                                                        [selectedCustomer]: details[2]
                                                                                    }])
                                                                                }
                                                                            }} className={`text-left text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-gray-50 text-[#4285F4]'}`}>

                                                                                {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                    <input
                                                                                        type="text"
                                                                                        defaultValue={
                                                                                            item["FQTY"] !== null ?
                                                                                                (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    (item["FQTY"]) : (item["FQTY_USER"]))
                                                                                                :
                                                                                                (0)
                                                                                        }
                                                                                        className={`p-2 h-full w-full bg-[#4285F4]
                                                                                            ${item["FQTY"] !== null ?
                                                                                                item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    "" : "text-white bg-[#ed7d31]"
                                                                                                :
                                                                                                ""}
                                                                                        `}
                                                                                        onChange={(e) => {
                                                                                            if (e.target.value !== item["FQTY"]) {
                                                                                                e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                            }
                                                                                            else {
                                                                                                e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                            }
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            setChanges(prevArray => prevArray.slice(0, -1))
                                                                                            setChanges((prevState) => [...prevState, {
                                                                                                FQTY_USER: parseInt(e.target.value),
                                                                                                interval: intervalFiltered[index],
                                                                                                [selectedItem]: details[0],
                                                                                                [selectedLocation]: details[1],
                                                                                                [selectedCustomer]: details[2]
                                                                                            }])
                                                                                        }}
                                                                                        autoFocus
                                                                                    />
                                                                                ) : (
                                                                                    <p
                                                                                        className={`p-2 h-full w-full
                                                                                    ${item["FQTY"] !== null ?
                                                                                                item["FQTY"] === item["FQTY_USER"] ?
                                                                                                    "" : "text-white bg-[#ed7d31]"
                                                                                                :
                                                                                                ""}
                                                                                `}                                                                                      >{(item["FQTY"] !== null ?
                                                                                            (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                (item["FQTY"]) : (item["FQTY_USER"]))
                                                                                            :
                                                                                            (0))}</p>
                                                                                )}
                                                                            </td>
                                                                        </>
                                                                    );
                                                                }
                                                                else if (item["SQTY"] !== null && item["SQTY"] !== 0 && index < midIndex) {
                                                                    return (
                                                                        <td onDoubleClick={() => {
                                                                            if (changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])) {
                                                                                setChanges(prevState => prevState.filter(change => !(change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])));
                                                                            }
                                                                            else {
                                                                                setChanges((prevState) => [...prevState, {
                                                                                    SQTY_USER: item.SQTY,
                                                                                    interval: intervalFiltered[index],
                                                                                    [selectedItem]: details[0],
                                                                                    [selectedLocation]: details[1],
                                                                                    [selectedCustomer]: details[2]
                                                                                }])
                                                                            }
                                                                        }} className={`text-left text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-gray-50 text-[#646666]'}`}>

                                                                            {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                <input
                                                                                    type="text"
                                                                                    defaultValue={item["SQTY"] !== null ? item["SQTY"] : 0}
                                                                                    className={`p-2 h-full w-full bg-[#4285F4]`}
                                                                                    onChange={(e) => {
                                                                                        if (e.target.value !== item["SQTY"]) {
                                                                                            e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                        }
                                                                                        else {
                                                                                            e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e) => {
                                                                                        setChanges(prevArray => prevArray.slice(0, -1))
                                                                                        setChanges((prevState) => [...prevState, {
                                                                                            SQTY_USER: parseInt(e.target.value),
                                                                                            interval: intervalFiltered[index],
                                                                                            [selectedItem]: details[0],
                                                                                            [selectedLocation]: details[1],
                                                                                            [selectedCustomer]: details[2]
                                                                                        }])
                                                                                    }}
                                                                                    autoFocus
                                                                                />
                                                                            ) : (
                                                                                <p className='p-2 h-full w-full'>{(item["SQTY"] !== null ? item["SQTY"] : 0)}</p>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                } else if (item["FQTY"] !== null && item["FQTY"] !== 0) {
                                                                    return (
                                                                        <td onDoubleClick={() => {

                                                                            if (changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])) {
                                                                                setChanges(prevState => prevState.filter(change => !(change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])));
                                                                            }
                                                                            else {
                                                                                setChanges((prevState) => [...prevState, {
                                                                                    FQTY_USER: item.FQTY,
                                                                                    interval: intervalFiltered[index],
                                                                                    [selectedItem]: details[0],
                                                                                    [selectedLocation]: details[1],
                                                                                    [selectedCustomer]: details[2]
                                                                                }])
                                                                            }
                                                                        }} className={`text-left text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-gray-50 text-[#4285F4]'}`}>
                                                                            {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                <input
                                                                                    type="text"
                                                                                    defaultValue={
                                                                                        item["FQTY"] !== null ?
                                                                                            (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                (item["FQTY"]) : (item["FQTY_USER"]))
                                                                                            :
                                                                                            (0)
                                                                                    }
                                                                                    className={`p-2 h-full w-full bg-[#4285F4]
                                                                                    ${item["FQTY"] !== null ?
                                                                                            item["FQTY"] === item["FQTY_USER"] ?
                                                                                                "" : "text-white bg-[#ed7d31]"
                                                                                            :
                                                                                            ""}
                                                                                `} onChange={(e) => {
                                                                                        if (e.target.value !== item["FQTY"]) {
                                                                                            e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                        }
                                                                                        else {
                                                                                            e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e) => {
                                                                                        setChanges(prevArray => prevArray.slice(0, -1))
                                                                                        setChanges((prevState) => [...prevState, {
                                                                                            FQTY_USER: parseInt(e.target.value),
                                                                                            interval: intervalFiltered[index],
                                                                                            [selectedItem]: details[0],
                                                                                            [selectedLocation]: details[1],
                                                                                            [selectedCustomer]: details[2]
                                                                                        }])
                                                                                    }}
                                                                                    autoFocus
                                                                                />
                                                                            ) : (
                                                                                <p
                                                                                    className={`p-2 h-full w-full
                                                                                ${item["FQTY"] !== null ?
                                                                                            item["FQTY"] === item["FQTY_USER"] ?
                                                                                                "" : "text-white bg-[#ed7d31]"
                                                                                            :
                                                                                            ""}
                                                                            `}                                                                                  >{(item["FQTY"] !== null ?
                                                                                        (item["FQTY"] === item["FQTY_USER"] ?
                                                                                            (item["FQTY"]) : (item["FQTY_USER"]))
                                                                                        :
                                                                                        (0))}</p>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                }
                                                                else if (item["SQTY"] !== null && item["SQTY"] === 0 && index < midIndex) {
                                                                    return (
                                                                        <td>
                                                                            <p className='p-1 h-full w-full'>{(item["SQTY"] !== null ? item["SQTY"] : 0)}</p>
                                                                        </td>
                                                                    );
                                                                }
                                                                else if (item["FQTY"] !== null && item["FQTY"] === 0) {
                                                                    return (
                                                                        <td onDoubleClick={() => {

                                                                            if (changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])) {
                                                                                setChanges(prevState => prevState.filter(change => !(change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])));
                                                                            }
                                                                            else {
                                                                                setChanges((prevState) => [...prevState, {
                                                                                    FQTY_USER: item.FQTY,
                                                                                    interval: intervalFiltered[index],
                                                                                    [selectedItem]: details[0],
                                                                                    [selectedLocation]: details[1],
                                                                                    [selectedCustomer]: details[2]
                                                                                }])
                                                                            }
                                                                        }} className={`text-left text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-gray-50 text-[#4285F4]'}`}>
                                                                            {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                <input
                                                                                    type="text"
                                                                                    defaultValue={
                                                                                        item["FQTY"] !== null ?
                                                                                            (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                (item["FQTY"]) : (item["FQTY_USER"]))
                                                                                            :
                                                                                            (0)
                                                                                    }
                                                                                    className={`p-2 h-full w-full bg-[#4285F4]
                                                                                    ${item["FQTY"] !== null ?
                                                                                            item["FQTY"] === item["FQTY_USER"] ?
                                                                                                "" : "text-white bg-[#ed7d31]"
                                                                                            :
                                                                                            ""}
                                                                                `} onChange={(e) => {
                                                                                        if (e.target.value !== item["FQTY"]) {
                                                                                            e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                        }
                                                                                        else {
                                                                                            e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e) => {
                                                                                        setChanges(prevArray => prevArray.slice(0, -1))
                                                                                        setChanges((prevState) => [...prevState, {
                                                                                            FQTY_USER: parseInt(e.target.value),
                                                                                            interval: intervalFiltered[index],
                                                                                            [selectedItem]: details[0],
                                                                                            [selectedLocation]: details[1],
                                                                                            [selectedCustomer]: details[2]
                                                                                        }])
                                                                                    }}
                                                                                    autoFocus
                                                                                />
                                                                            ) : (
                                                                                <p
                                                                                    className={`p-2 h-full w-full
                                                                                ${item["FQTY"] !== null ?
                                                                                            item["FQTY"] === item["FQTY_USER"] ?
                                                                                                "" : "text-white bg-[#ed7d31]"
                                                                                            :
                                                                                            ""}
                                                                            `}                                                                                  >{(item["FQTY"] !== null ?
                                                                                        (item["FQTY"] === item["FQTY_USER"] ?
                                                                                            (item["FQTY"]) : (item["FQTY_USER"]))
                                                                                        :
                                                                                        (0))}</p>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                }
                                                                else if (item["SQTY"] === null && item["FQTY"] === null && index > midIndex) {
                                                                    return (
                                                                        <td onDoubleClick={() => {

                                                                            if (changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])) {
                                                                                setChanges(prevState => prevState.filter(change => !(change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index])));
                                                                            }
                                                                            else {
                                                                                setChanges((prevState) => [...prevState, {
                                                                                    FQTY_USER: item.FQTY,
                                                                                    interval: intervalFiltered[index],
                                                                                    [selectedItem]: details[0],
                                                                                    [selectedLocation]: details[1],
                                                                                    [selectedCustomer]: details[2]
                                                                                }])
                                                                            }
                                                                        }} className={`text-left text-xs px-0 -py-1  ${changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) ? ' text-white' : 'bg-gray-50 text-[#4285F4]'}`}>
                                                                            {changes.some(change => change[selectedItem] === details[0] && change[selectedLocation] === details[1] && change[selectedCustomer] === details[2] && change["interval"] === intervalFiltered[index]) === true ? (
                                                                                <input
                                                                                    type="text"
                                                                                    defaultValue={
                                                                                        item["FQTY"] !== null ?
                                                                                            (item["FQTY"] === item["FQTY_USER"] ?
                                                                                                (item["FQTY"]) : (item["FQTY_USER"]))
                                                                                            :
                                                                                            (0)
                                                                                    }
                                                                                    className={`p-2 h-full w-full bg-[#4285F4]
                                                                                    ${item["FQTY"] !== null ?
                                                                                            item["FQTY"] === item["FQTY_USER"] ?
                                                                                                "" : "text-white bg-[#ed7d31]"
                                                                                            :
                                                                                            ""}
                                                                                `} onChange={(e) => {
                                                                                        if (e.target.value !== item["FQTY"]) {
                                                                                            e.target.className = "p-2 h-full w-full bg-[#ed7d31]"
                                                                                        }
                                                                                        else {
                                                                                            e.target.className = "p-2 h-full w-full bg-[#4285F4]"
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e) => {
                                                                                        setChanges(prevArray => prevArray.slice(0, -1))
                                                                                        setChanges((prevState) => [...prevState, {
                                                                                            FQTY_USER: parseInt(e.target.value),
                                                                                            interval: intervalFiltered[index],
                                                                                            [selectedItem]: details[0],
                                                                                            [selectedLocation]: details[1],
                                                                                            [selectedCustomer]: details[2]
                                                                                        }])
                                                                                    }}
                                                                                    autoFocus
                                                                                />
                                                                            ) : (
                                                                                <p
                                                                                    className={`p-2 h-full w-full
                                                                                ${item["FQTY"] !== null ?
                                                                                            item["FQTY"] === item["FQTY_USER"] ?
                                                                                                "" : "text-white bg-[#ed7d31]"
                                                                                            :
                                                                                            ""}
                                                                            `}                                                                                >{(item["FQTY"] !== null ?
                                                                                        (item["FQTY"] === item["FQTY_USER"] ?
                                                                                            (item["FQTY"]) : (item["FQTY_USER"]))
                                                                                        :
                                                                                        (0))}</p>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                }

                                                            })}
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <table ref={tableRef} className='resizable-table w-full relative whitespace-nowrap text-xs table-auto shadow-class bg-white  transition-all ease-in-out duration-500 '>
                                        <thead className='w-full sticky top-0 border-b shadow-sm shadow-class bg-white z-50 ' >
                                            <tr className='font-bold  bg-slate-100'>
                                                {/* <td className='sticky bg-slate-100 shadow-col left-0 z-40 min-w-[30px]  text-center text-base text-blue-500  no-download-col'>
                                                    <button className='w-full h-full' onClick={() => setCheckBoxOverlay(true)}><TbTablePlus /></button>
                                                </td> */}
                                                <td className='sticky bg-slate-100 shadow-col left-0 min-w-[90px]   z-40 text-center text-base '>
                                                    <div className='flex flex-row items-center justify-between px-3'>
                                                        <div className='flex items-center'>
                                                            <button className='w-full h-full hover:text-blue-400' onClick={() => setCheckBoxOverlay(true)}><TbTablePlus /></button>
                                                        </div>
                                                        <div
                                                            className=" hover:text-blue-500 text-sm">
                                                            {
                                                                !isExpanded ?
                                                                    <ExpandOutlined className='cursor-pointer'
                                                                        onClick={() => {
                                                                            setIsExpanded(true);
                                                                        }} />
                                                                    :
                                                                    <IoMdContract className='cursor-pointer'
                                                                        onClick={() => {
                                                                            setIsExpanded(false);
                                                                        }} />
                                                            }

                                                        </div>
                                                        {/* <input type='checkbox'
                                                            // id="childCheckbox"
                                                            // checked={selectedItems.includes(data)}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    // const correspondingObject = supplyTransposedData.get(data);
                                                                    // setSelectedItems(correspondingObject)
                                                                    // checkedGraphData(correspondingObject)
                                                                    // setSelectedItems(prevState => [...prevState, data])
                                                                }
                                                                else {
                                                                    // const correspondingObject = supplyTransposedData.get(data);
                                                                    // uncheckGraphData(correspondingObject)
                                                                    // setSelectedItems(selectedItems.filter(item => item !== data))
                                                                }
                                                            }} className=' text-base' /> */}
                                                        <div onClick={() => uncheckAll()} className='hover:text-blue-500 cursor-pointer'>

                                                            <CgRemoveR />
                                                        </div>

                                                    </div>

                                                </td>
                                                {additionalFields.length === 0 &&

                                                    <>
                                                        <td
                                                            onDoubleClick={() => sortData(supplyTransposedData, 0)}
                                                            ref={col1Ref}
                                                            // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][supFields['item']].toString().length > 15 ? uiMappingData[0][supFields['item']] : ""}
                                                            style={{ minWidth: `${columnWidths.col1}px`, maxWidth: `${columnWidths.col1}px` }}
                                                            className={`sticky cursor-pointer ${sortedIndex === 0 && keySorting ? "bg-blue-100" : "bg-slate-100"}  shadow-col  left-[90px] z-40 overflow-hidden  rounded-tl-sm text-left  text-xs py-1 border-1 border-gray-200 `}>
                                                            <div className='flex flex-row gap-1 items-center px-3'>
                                                                <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(supFields['item']) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                    <FilterOutlined />
                                                                </div>
                                                                {/* <div className='text-base  flex flex-col justify-start items-center '>
                                                                    <div onClick={() => sortData(supplyTransposedData, 0)} className={`${sortOrder === true && sortedIndex === 0 && keySorting ? "text-blue-500" : ""} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                        <FaSortUp />
                                                                    </div>
                                                                    <div onClick={() => sortData(supplyTransposedData, 0)} className={`${sortOrder === false && sortedIndex === 0 && keySorting ? "text-blue-500" : ""} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                        <FaSortDown />
                                                                    </div>
                                                                </div> */}
                                                                <div className='overflow-hidden'>
                                                                    {uiMappingData[0][supFields['item']]}
                                                                </div>
                                                                <div className='plan-select  cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                    <select
                                                                        className={`h-[1.5rem] w-[1.5rem] outline-none  appearance-none px-1 relative`}
                                                                        onChange={(e) => {
                                                                            setsupFields({ ...supFields, "item": e.target.value });
                                                                            setSelectedItem(e.target.value);
                                                                        }}
                                                                        value=""
                                                                    >
                                                                        <option value="" disabled hidden></option>
                                                                        {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                                            if (item.toString().substring(0, 1) === "I" && uiMappingData[0][item] !== null) {
                                                                                return (
                                                                                    <option key={item} value={item}>
                                                                                        {uiMappingData[0][item]}
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </select>

                                                                </div>

                                                            </div>
                                                            <div
                                                                className="resize-handle"
                                                                onMouseDown={(e) => handleMouseDown(e, 'col1', col1Ref)}
                                                            />

                                                        </td>
                                                        <td
                                                            onDoubleClick={() => sortData(supplyTransposedData, 1)}
                                                            ref={col2Ref}
                                                            // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][supFields['location']].toString().length > 15 ? uiMappingData[0][supFields['location']] : ""}
                                                            style={{ minWidth: `${columnWidths.col2}px`, maxWidth: `${columnWidths.col2}px`, left: `${columnWidths.col1 + 90}px` }}
                                                            className={`sticky  shadow-col cursor-pointer ${sortedIndex === 1 && keySorting ? "bg-blue-100" : "bg-slate-100"} overflow-hidden   z-40 rounded-tl-sm text-center  text-xs py-1 border-1 border-gray-200 `}>
                                                            <div className='flex flex-row gap-1 items-center   px-3'>
                                                                <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(supFields['location']) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                    <FilterOutlined />
                                                                </div>
                                                                {/* <div className='text-base  flex flex-col justify-start items-center '>
                                                                    <div onClick={() => sortData(supplyTransposedData, 1, true)} className={`${sortOrder === true && sortedIndex === 1 && keySorting ? "text-blue-500" : ""} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                        <FaSortUp />
                                                                    </div>
                                                                    <div onClick={() => sortData(supplyTransposedData, 1, false)} className={`${sortOrder === false && sortedIndex === 1 && keySorting ? "text-blue-500" : ""} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                        <FaSortDown />
                                                                    </div>
                                                                </div> */}
                                                                <div className='overflow-hidden'>
                                                                    {uiMappingData[0][supFields['location']]}
                                                                </div>
                                                                <div className='plan-select  cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                    <select
                                                                        className={`h-[1.5rem] w-[1.5rem] outline-none  appearance-none px-1 relative`}
                                                                        onChange={(e) => {
                                                                            setsupFields({ ...supFields, "location": e.target.value });
                                                                            setSelectedLocation(e.target.value)
                                                                        }}
                                                                        value={""}>
                                                                        <>
                                                                            <option value="" disabled hidden></option>
                                                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((item) => {
                                                                                if (item.toString().substring(0, 1) === "L" && uiMappingData[0][item] !== null) {
                                                                                    return (
                                                                                        <option value={item}>{uiMappingData[0][item]}</option>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </>
                                                                    </select>
                                                                </div>

                                                            </div>
                                                            <div
                                                                className="resize-handle"
                                                                onMouseDown={(e) => handleMouseDown(e, 'col2', col2Ref)}
                                                            />
                                                        </td>

                                                    </>
                                                }
                                                {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                                    // Calculate the sum of all previous columns' widths
                                                    const sumOfPrevWidths = Object.keys(dynamicColumnWidths)
                                                        .filter((key, idx) => idx < index) // Only consider keys (cols) before the current index
                                                        .reduce((sum, key) => sum + dynamicColumnWidths[key], 0); // Sum the widths of previous columns


                                                    // Determine the field type
                                                    let fieldType = '';
                                                    let fieldKey = '';
                                                    if (item.toString()[0] === 'I') {
                                                        fieldType = 'item';
                                                        fieldKey = 'I';
                                                    } else if (item.toString()[0] === 'L') {
                                                        fieldType = 'location';
                                                        fieldKey = 'L';
                                                    } else if (item.toString()[0] === 'C') {
                                                        fieldType = 'category';
                                                        fieldKey = 'C';
                                                    }

                                                    // Function to handle change based on the field type
                                                    const handleChange = (e) => {
                                                        setsupFields({ ...supFields, [fieldType]: e.target.value });
                                                        const updatedColumns = columnsShown.map(data => data === item ? e.target.value : data);
                                                        setColumnShown(updatedColumns);
                                                        fieldType === 'item' ? setSelectedItem(e.target.value) :
                                                            fieldType === 'location' ? setSelectedLocation(e.target.value) :
                                                                setSelectedCustomer(e.target.value);
                                                    };
                                                    if (item.toString()[0] !== 'C') {
                                                        return (
                                                            <td
                                                                ref={columnRefs.current[`col${index}`]}
                                                                // data-tooltip-id="my-tooltip" data-tooltip-content={uiMappingData[0][item].toString().length > 15 ? uiMappingData[0][item] : ""}
                                                                onDoubleClick={() => sortData(supplyTransposedData, index)}
                                                                style={{ left: `${index === 0 ? 90 : sumOfPrevWidths + 90}px`, maxWidth: `${dynamicColumnWidths[`col${index}`]}px`, minWidth: `${dynamicColumnWidths[`col${index}`]}px` }}
                                                                key={index}
                                                                className={`sticky ${sortedIndex === index && keySorting ? "bg-blue-100" : "bg-slate-100"} shadow-col overflow-hidden cursor-pointer px-3 text-xs py-1 border-1 border-gray-200 z-40`}
                                                            >
                                                                <div className='flex flex-row gap-1 items-center  px-3'>
                                                                    <div onClick={() => { setinATTFilterPopUp(true); setFilterATT(item) }} className='text-base hover:text-blue-500 cursor-pointer'>
                                                                        <FilterOutlined />
                                                                    </div>
                                                                    <div className='overflow-hidden'>
                                                                        {uiMappingData[0][item]}
                                                                    </div>
                                                                    <div className='plan-select cursor-pointer hover:bg-violet-100 rounded-full'>
                                                                        <select
                                                                            className={`h-[1.5rem] w-[1.5rem] outline-none appearance-none px-1 relative`}
                                                                            onChange={handleChange}
                                                                            value={""}
                                                                        >
                                                                            <option value="" disabled hidden></option>
                                                                            {uiMappingData !== null && Object.keys(uiMappingData[0]).map((data) => {
                                                                                if (data.toString()[0] === fieldKey && uiMappingData[0][data] !== null) {
                                                                                    return (
                                                                                        <option value={data} selected={uiMappingData[0][item] === uiMappingData[0][data]}>
                                                                                            {uiMappingData[0][data]}
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className='resize-handle'
                                                                    style={{ cursor: 'col-resize', padding: '0 5px' }}
                                                                    onMouseDown={(e) => handleMouseDownDynamic(e, `col${index}`)}
                                                                />
                                                            </td>
                                                        );
                                                    }
                                                })}
                                                {
                                                    showAverage &&

                                                    <td

                                                        className='sticky z-40 shadow-col min-w-[130px] max-w-[130px] bg-slate-100 p-0'
                                                        style={{ left: `${additionalFields.length === 0 ? columnWidths.col1 + columnWidths.col2 + columnWidths.col3 + 90 : Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 90)}px` }}
                                                    >
                                                        <div className=' w-full flex flex-col h-full'>
                                                            <div className='flex w-full pb-1'>

                                                                <p className={`text-center w-full`}>Average</p>

                                                            </div>

                                                            <div className='flex justify-between font-normal border-t text-slate-400'>
                                                                <p onDoubleClick={() => sortMapBySumAvg(supplyTransposedData, 'average', 'sales')} className={`border-r w-full text-center hover:text-green-500 cursor-pointer ${sortString === 'average-sales' ? "text-green-600" : ""}`}>Sales</p>
                                                                <p onDoubleClick={() => sortMapBySumAvg(supplyTransposedData, 'average', 'forecast')} className={`w-full text-center hover:text-green-500 cursor-pointer ${sortString === 'average-forecast' ? "text-green-600" : ""}`}>Forecast</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {
                                                    showSum &&

                                                    <td

                                                        className='sticky z-40 shadow-col min-w-[130px] max-w-[130px] bg-slate-100 p-0'
                                                        style={{ left: `${additionalFields.length === 0 ? columnWidths.col1 + columnWidths.col2 + columnWidths.col3 + 90 + (showAverage ? 130 : 0) : Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 90 + (showAverage ? 130 : 0))}px` }}
                                                    >
                                                        <div className=' w-full flex flex-col h-full'>
                                                            <div className='flex w-full pb-1'>

                                                                <p className={`text-center w-full`}>Sum</p>

                                                            </div>

                                                            <div className='flex justify-between font-normal border-t text-slate-400'>
                                                                <p onDoubleClick={() => sortMapBySumAvg(supplyTransposedData, 'sum', 'sales')} className={`border-r w-full text-center hover:text-green-500 cursor-pointer ${sortString === 'sum-sales' ? "text-green-600" : ""}`}>Sales</p>
                                                                <p onDoubleClick={() => sortMapBySumAvg(supplyTransposedData, 'sum', 'forecast')} className={`w-full text-center hover:text-green-500 cursor-pointer ${sortString === 'sum-forecast' ? "text-green-600" : ""}`}>Forecast</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }


                                                {supplyTransposedData.size !== 0 && Object.keys(supplyTransposedData.get([...supplyTransposedData.keys()][0])).map((item, index) => {


                                                    if (item === tdateVar && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                        midIndex = index
                                                        return (<React.Fragment key={index}>
                                                            <td ref={tdRef} colSpan={1} className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""}  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                <div className='flex flex-row items-center gap-1 justify-between'>
                                                                    <div className='flex flex-col items-start'>
                                                                        <p>{new Date(item).toLocaleDateString('en-US', { month: 'short' })}-{new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}</p>
                                                                        <div className='flex justify-between gap-6 w-full ' >
                                                                            <p className='text-slate-400 font-normal'>
                                                                                {Intl.NumberFormat('en-US', {
                                                                                    notation: "compact",
                                                                                    maximumFractionDigits: 1
                                                                                }).format(totalSqty[index])}
                                                                            </p>
                                                                            <p className='text-slate-400 font-normal'>
                                                                                {Intl.NumberFormat('en-US', {
                                                                                    notation: "compact",
                                                                                    maximumFractionDigits: 1
                                                                                }).format(totalFqty[index])}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-base  flex flex-col justify-start items-center '>
                                                                        <div onClick={() => sortDataByDate(item, supplyTransposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                            <FaSortUp />
                                                                        </div>
                                                                        <div onClick={() => sortDataByDate(item, supplyTransposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                            <FaSortDown />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </React.Fragment>)
                                                    }
                                                    else {
                                                        if (midIndex !== 0 && new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate) && index === midIndex + 4) {
                                                            return (<React.Fragment key={index}>
                                                                <td className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""}  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                    <div className='flex flex-row items-center gap-3 justify-between'>
                                                                        <div className='flex flex-col items-start'>
                                                                            <p>{new Date(item).toLocaleDateString('en-US', { month: 'short' })}-{new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}</p>
                                                                            <p className='text-slate-400 font-normal gap-2 flex justify-between items-center'>
                                                                                <span>

                                                                                    {Intl.NumberFormat('en-US', {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1
                                                                                    }).format(index < midIndex ? totalSqty[index] : totalFqty[index])}
                                                                                </span>
                                                                                {/* <span className='flex items-center justify-center text-lg'>
                                                                                    <div onClick={() => sortDataByDate(item, supplyTransposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedUp />
                                                                                    </div>
                                                                                    <div onClick={() => sortDataByDate(item, supplyTransposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedDown />
                                                                                    </div>
                                                                                </span> */}
                                                                            </p>
                                                                        </div>
                                                                        <div className='text-base  flex flex-col justify-start items-center '>
                                                                            <div onClick={() => sortDataByDate(item, supplyTransposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                <FaSortUp />
                                                                            </div>
                                                                            <div onClick={() => sortDataByDate(item, supplyTransposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                <FaSortDown />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>)
                                                        }
                                                        if (new Date(item) >= new Date(startDate) && new Date(item) <= new Date(endDate)) {
                                                            return (<React.Fragment key={index}>
                                                                <td className={`${sortedIndex === index && !keySorting ? "bg-blue-100" : ""}  rounded-tl-sm shadow-col min-w-[110px] max-w-[110px] text-center px-3 text-xs py-1 border-1 border-gray-200`}>
                                                                    <div className='flex flex-row items-center gap-3 justify-between'>
                                                                        <div className='flex flex-col items-start'>
                                                                            <p>{new Date(item).toLocaleDateString('en-US', { month: 'short' })}-{new Date(item).toLocaleDateString('en-US', { year: '2-digit' })}</p>
                                                                            <p className='text-slate-400 font-normal gap-2 flex justify-between items-center'>
                                                                                <span>

                                                                                    {Intl.NumberFormat('en-US', {
                                                                                        notation: "compact",
                                                                                        maximumFractionDigits: 1
                                                                                    }).format(index < midIndex ? totalSqty[index] : totalFqty[index])}
                                                                                </span>
                                                                                {/* <span className='flex items-center justify-center text-lg'>
                                                                                    <div onClick={() => sortDataByDate(item, supplyTransposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedUp />
                                                                                    </div>
                                                                                    <div onClick={() => sortDataByDate(item, supplyTransposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : ""} flex items-center justify-center cursor-pointer  hover:text-blue-500`}>
                                                                                        <TiArrowSortedDown />
                                                                                    </div>
                                                                                </span> */}
                                                                            </p>
                                                                        </div>
                                                                        <div className='text-base  flex flex-col justify-start items-center '>
                                                                            <div onClick={() => sortDataByDate(item, supplyTransposedData, index, true)} className={`${sortOrder === true && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                <FaSortUp />
                                                                            </div>
                                                                            <div onClick={() => sortDataByDate(item, supplyTransposedData, index, false)} className={`${sortOrder === false && sortedIndex === index && !keySorting ? "text-blue-500" : "text-slate-400"} flex items-center justify-center h-1 -pt-1 cursor-pointer  hover:text-blue-500`}>
                                                                                <FaSortDown />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </React.Fragment>)
                                                        }
                                                    }
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {supplyTransposedData.size !== 0 && [...supplyTransposedData.keys()].map((data, rowIndex) => {
                                                const details = data.split("=");
                                                return (
                                                    <>

                                                        <tr

                                                            className={`font-semibold  transition-all ease-in-out  duration-500 ${isIndexPresent(rowIndex) ? 'sticky z-50 shadow-col left-0 h-8' : ''}`}
                                                            style={isIndexPresent(rowIndex) ? { top: `${getTopForIndex(rowIndex)}rem` } : {}}
                                                            key={rowIndex}>


                                                            {/* <td className='sticky min-w-[30px] shadow-col bg-white left-0 z-30 transform-all ease-in-out duration-500 h-full font-bold text-lg text-center hover:bg-slate-100 cursor-pointer'></td> */}

                                                            {<td className={`sticky ${expandRow === rowIndex ? "bg-violet-100" : "bg-white"}  shadow-col left-0 min-w-[90px]  z-30  text-center text-base no-download-col`}>
                                                                <div className='flex flex-row items-center px-3 justify-between'>


                                                                    <div
                                                                        onClick={() => {

                                                                            if (expandRow === rowIndex) {
                                                                                setExpandRow(null)

                                                                            }
                                                                            else {
                                                                                setExpandRow(rowIndex)
                                                                                getProposals(details)
                                                                            }
                                                                        }
                                                                        }
                                                                        className={`transform-all cursor-pointer hover:text-blue-500 ease-in-out duration-200 ${expandRow === rowIndex ? "rotate-180 transform-all ease-in-out duration-200" : ""}`}>
                                                                        < MdOutlineExpandCircleDown />
                                                                    </div>
                                                                    <input type='checkbox'
                                                                        ref={(el) => (checkboxRefs.current[rowIndex] = el)} // Assign ref to each checkbox
                                                                        onKeyDown={(e) => handleKeyDown(e, rowIndex)} // Handle up/down keys
                                                                        id="childCheckbox"
                                                                        checked={selectedItems.includes(data)}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                // const correspondingObject = supplyTransposedData.get(data);
                                                                                // checkedGraphData(correspondingObject, rowIndex)
                                                                                setSelectedItems(prevState => [...prevState, data])
                                                                            }
                                                                            else {
                                                                                // const correspondingObject = supplyTransposedData.get(data);
                                                                                // uncheckGraphData(correspondingObject, rowIndex)
                                                                                setSelectedItems(selectedItems.filter(item => item !== data))
                                                                            }
                                                                        }} className='text-center text-base' />
                                                                </div>
                                                            </td>}

                                                            {
                                                                additionalFields.length === 0 &&
                                                                <>
                                                                    <td
                                                                        style={{ minWidth: `${columnWidths.col1}px`, maxWidth: `${columnWidths.col1}px`, left: `${90}px` }}
                                                                        className={`sticky z-30 overflow-hidden ${expandRow === rowIndex ? "bg-violet-100" : "bg-white"}  shadow-col px-4    text-left  py-2 text-xs  `}
                                                                    // data-tooltip-id="my-tooltip" data-tooltip-content={details[0].toString().length > 22 ? details[0] : ""}
                                                                    >
                                                                        {details[0] !== null ? details[0] : "Not Available"}
                                                                    </td>
                                                                    <td
                                                                        style={{ minWidth: `${columnWidths.col2}px`, maxWidth: `${columnWidths.col2}px`, left: `${columnWidths.col1 + 90}px` }}
                                                                        className={`sticky z-30 overflow-hidden ${expandRow === rowIndex ? "bg-violet-100" : "bg-white"}  shadow-col px-4     text-left  py-2 text-xs `}
                                                                    // data-tooltip-id="my-tooltip" data-tooltip-content={details[1].toString().length > 22 ? details[1] : ""}
                                                                    >
                                                                        {details[1] !== null ? details[1] : "Not Available"}
                                                                    </td>

                                                                </>
                                                            }
                                                            {additionalFields.length !== 0 && additionalFields.map((item, index) => {
                                                                const sp = (90 + ((index) * 180))

                                                                const leftValue = `${sp}px`;

                                                                // Calculate the sum of all previous columns' widths
                                                                const sumOfPrevWidths = Object.keys(dynamicColumnWidths)
                                                                    .filter((key, idx) => idx < index) // Only consider keys (cols) before the current index
                                                                    .reduce((sum, key) => sum + dynamicColumnWidths[key], 0); // Sum the widths of previous columns

                                                                if (item.toString()[0] !== 'C') {
                                                                    return (
                                                                        <td
                                                                            // data-tooltip-id="my-tooltip" data-tooltip-content={details[index] !== null && details[index].toString().length > 22 ? details[index] : ""}
                                                                            style={{ left: `${index === 0 ? 90 : sumOfPrevWidths + 90}px`, maxWidth: `${dynamicColumnWidths[`col${index}`]}px`, minWidth: `${dynamicColumnWidths[`col${index}`]}px` }}
                                                                            className={`sticky z-30 text-left overflow-hidden px-3 py-2
                                                                         shadow-col   text-xs ${expandRow === rowIndex ? "bg-violet-100" : "bg-white"}  `} key={index}>{details[index] !== null ? details[index] : "Not Available"}</td>
                                                                    )
                                                                }

                                                            })}
                                                            {
                                                                showAverage &&

                                                                <td
                                                                    className={`sticky z-40 shadow-col min-w-[130px] max-w-[130px] p-0 ${expandRow === rowIndex ? "bg-violet-100" : "bg-white"}`}
                                                                    style={{ left: `${additionalFields.length === 0 ? columnWidths.col1 + columnWidths.col2 + columnWidths.col3 + 90 : Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 90)}px` }}
                                                                >
                                                                    <div className='flex justify-between font-normal min-h-full'>
                                                                        <p className='border-r w-full text-center'>
                                                                            {calculateSumAndAvg(supplyTransposedData.get(data), 'sales', false)}
                                                                        </p>
                                                                        <p className='w-full text-center'>
                                                                            {calculateSumAndAvg(supplyTransposedData.get(data), 'forecast', false)}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            }
                                                            {
                                                                showSum &&

                                                                <td
                                                                    className={`sticky z-40 shadow-col min-w-[130px] max-w-[130px] p-0 ${expandRow === rowIndex ? "bg-violet-100" : "bg-white"}`}
                                                                    style={{ left: `${additionalFields.length === 0 ? columnWidths.col1 + columnWidths.col2 + columnWidths.col3 + 90 + (showAverage ? 130 : 0) : Object.values(dynamicColumnWidths).reduce((acc, value) => acc + value, 90 + (showAverage ? 130 : 0))}px` }}
                                                                >
                                                                    <div className='flex justify-between font-normal min-h-full'>
                                                                        <p className='border-r w-full text-center'>
                                                                            {calculateSumAndAvg(supplyTransposedData.get(data), 'sales', true)}
                                                                        </p>
                                                                        <p className='w-full text-center'>
                                                                            {calculateSumAndAvg(supplyTransposedData.get(data), 'forecast', true)}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            }


                                                            {supplyTransposedData.size !== 0 && Object.values(supplyTransposedData.get(data)).map((item, index) => {



                                                                return (
                                                                    <td className={`${expandRow === rowIndex ? "bg-violet-100" : "bg-gray-50"}  min-w-[110px] max-w-[110px]`}>
                                                                        <p className='p-2 h-full w-full'>{Intl.NumberFormat('en-US', {
                                                                            notation: "compact",
                                                                            maximumFractionDigits: 1
                                                                        }).format(item["PRQTY"] !== null ? parseFloat(Number(item["PRQTY"]).toFixed(2)).toString() : 0)}</p>
                                                                    </td>
                                                                );





                                                            })}
                                                        </tr>
                                                        {
                                                            expandRow === rowIndex &&
                                                            <>







                                                                {/* <tr className=''>
                                                                        <td colSpan={1000} className='sticky p-0'>
                                                                            <ExpandedRowTable/>
                                                                        </td>
                                                                    </tr> */}









                                                                {
                                                                    Expandchecklist['BOH'] &&
                                                                    <>
                                                                        <CommonExpandedRow name={"BOH"} additionalFields={additionalFields} trasnposedData={supplyTransposedData} data={data} midIndex={midIndex} />

                                                                    </>
                                                                }

                                                                {
                                                                    Expandchecklist['EOH'] &&
                                                                    <>
                                                                        <CommonExpandedRow name={"EOH"} additionalFields={additionalFields} trasnposedData={supplyTransposedData} data={data} midIndex={midIndex} />

                                                                    </>
                                                                }
                                                                {
                                                                    Expandchecklist['POERQTY'] &&
                                                                    <>
                                                                        <CommonExpandedRow name={"POERQTY"} additionalFields={additionalFields} trasnposedData={supplyTransposedData} data={data} midIndex={midIndex} />

                                                                    </>
                                                                }
                                                                {
                                                                    Expandchecklist['STERQTY'] &&
                                                                    <>
                                                                        <CommonExpandedRow name={"STERQTY"} additionalFields={additionalFields} trasnposedData={supplyTransposedData} data={data} midIndex={midIndex} />

                                                                    </>
                                                                }










                                                                <tr className=''>
                                                                    <td colSpan={13} className='sticky left-0 border-none w-full p-0 z-10'>
                                                                        <ExpandCheckboxList Expandchecklist={Expandchecklist} setExpandchecklist={setExpandchecklist} />
                                                                    </td>
                                                                </tr>

                                                            </>
                                                        }
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </>)}

                            <div
                                onClick={() => scrollToOffsetCenter()}
                                className='fixed bottom-3 hover:bg-blue-500 hover:text-white cursor-pointer h-3  left-[50%] flex text-base px-1 justify-center items-center z-50 transform  border-2 rounded-full shadow-sm bg-white border-blue-500'>
                                <div className='rotate-45'>
                                    <PiArrowsInSimple />
                                </div>
                            </div>
                            <div onClick={() => {
                                tableRef.current.scrollIntoView({ behavior: 'smooth' });
                            }} className='fixed bottom-4 hover:bg-blue-500 hover:text-white cursor-pointer w-3  right-3 flex text-base  justify-center items-center z-50 transform  border-2 rounded-full shadow-sm bg-white border-blue-500'>
                                <div className=''>

                                    <TbArrowNarrowUp />
                                </div>
                            </div>
                        </div>
                        {checkBoxOverLay === true ?
                            <Overlay
                                supFields={columnsShown.length !== 0 ? columnsShown : [supFields["item"], supFields["location"], supFields["category"]]}
                                locationFilters={locationFilters}
                                productFilters={productFilters}
                                customerFilters={customerFilters}
                                uiMappingData={uiMappingData}
                                setsupFields={setColumnShown}
                                setNext={setNext}
                                onClose={() => setCheckBoxOverlay(false)} /> : null}
                        {
                            overlayFlag === true ?
                                <EditOverlay

                                    adjLoading={adjLoading}
                                    setAdjLoading={setAdjLoading}
                                    getData={getData}
                                    getGraphData={getGraphData}
                                    changes={changes}
                                    setOverlayFlag={setOverlayFlag}
                                    setChanges={setChanges} /> : null
                        }
                        {
                            inATTFilterPopUp &&
                            <FilterInATTPopUp />
                        }
                        {/* {
                            true &&
                            <DetailedProposal
                                setSelectedItem={setSelectedItem}
                                setSelectedLocation={setSelectedLocation}
                                setSelectedCustomer={setSelectedCustomer}
                                supFields={supFields}
                                setsupFields={setsupFields}
                                uiMappingData={uiMappingData}
                                additionalFields={additionalFields}
                                supplyTransposedData={supplyTransposedData}
                                midIndex={midIndex}
                                tdateVar={tdateVar}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        } */}
                        {
                            graphEditPopUp &&
                            <GraphEditPopUp />
                        }

                    </>
            }
            <Tooltip id="my-tooltip" />

        </div>
    )
}

export default SupplyView